import React, { useState, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl-phraseapp';
import { Modal2, Text } from 'kolkit';

import { useSelector } from 'utils/redux';
import useLocalStorage from 'utils/hooks/useLocalStorage';
import {
  minimumSupportedBrowserVersion,
  minimumSupportedEngineVersion,
  browserDownloadLinks,
} from 'config/browser';

import styles from 'css/scss/ui/unsupportedBrowserWarning.module.scss';

const getFloatVersionFromString = (str) => {
  const [major, minor] = str.split('.').map(Number);
  return parseFloat(`${major}.${minor}`);
};

const IsUnsupportedBrowser = (
  browserName,
  stringBrowserVersion,
  engineName,
  engineVersion,
) => {
  const minBrowserVersion =
    minimumSupportedBrowserVersion[browserName?.toLowerCase()];
  if (!minBrowserVersion) {
    // If not in browser list, first check if it's a suppported engine
    if (minimumSupportedEngineVersion[engineName?.toLowerCase()]) {
      return (
        getFloatVersionFromString(engineVersion) <
        minimumSupportedEngineVersion[engineName?.toLowerCase()]
      );
    }

	// Not a supported engine
    return true;
  }

  const majorMinor = getFloatVersionFromString(stringBrowserVersion);

  return majorMinor < minBrowserVersion;
};

const OutdatedBrowserAlert = () => {
	const intl = useIntl()
	const [wishNoMoreAlert, setWishNoMoreAlert] = useState(false);
	const [isIgnored, setIsIgnored] = useLocalStorage('alert_outdated_browser_2024_10', false); // Change local storage key to force user to see the alert again
	const { browserName, browserVersion, isDesktop, engineName, engineVersion } = useSelector(({ env, ui }) => ({
		browserName: env.browser.name,
		browserVersion: env.browser.version,
		engineName: env.browser.engine,
		engineVersion: env.browser.engineVersion,
		isDesktop: ui.device.isDesktop
	}));

	const unsupportedBrowser = IsUnsupportedBrowser(browserName, browserVersion, engineName, engineVersion);

	const [modalOpen, setModalOpen] = useState(unsupportedBrowser && !isIgnored);

	const closeModal = useCallback(
		() => {
			setModalOpen(open => !open);
			setIsIgnored(wishNoMoreAlert);
		},
		[setIsIgnored, wishNoMoreAlert]
	);

	const handleCheck = useCallback(
		() => setWishNoMoreAlert(s => !s),
		[],
	  );

	const openChromeDownloadPage = () => {
		closeModal();
		window.open(browserDownloadLinks.chrome, '_blank');
	};

	const actions = {
		primary: {
			title: intl.formatMessage({ id: "unsupportedBrowser.downloadChrome" }),
			onClick: openChromeDownloadPage
		},
		secondary: {
			title: intl.formatMessage({ id: "unsupportedBrowser.continueInDegradedMode" }),
			onClick: closeModal
		},
		checkbox: {
			title: intl.formatMessage({ id: "unsupportedBrowser.wishNoMoreAlert" }),
			checked: wishNoMoreAlert,
			onChange: handleCheck,
		},
	};

	if (!isDesktop || !unsupportedBrowser) return null;

	return (
		<Modal2
			on={modalOpen}
			action={actions}
			title={intl.formatMessage({ id: "unsupportedBrowser.title" })}
			onClick={closeModal}
			size="large"
		>
			<Text>
				<FormattedMessage id="unsupportedBrowser.description" />
				<a
					className={styles.updateBrowser}
					rel="noopener noreferrer"
					target="_blank"
					href={browserDownloadLinks[browserName] || ''}
				>
					<FormattedMessage id="unsupportedBrowser.update" />
				</a>
			</Text>
			<Text tag="span" className={styles.recommendation}>
				<FormattedMessage id="unsupportedBrowser.recommend" />
			</Text>
		</Modal2>
	)
};

export default OutdatedBrowserAlert;
