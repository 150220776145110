/* eslint-disable no-param-reassign */
import _uniqBy from 'lodash.uniqby';
import _sortBy from 'lodash.sortby';
import _orderBy from 'lodash.orderby';
import { createReducer } from '@reduxjs/toolkit';
import * as MESSAGING from 'constants/messaging';
import { PROFILE_TYPES } from 'components/messaging/Constants';

const emptyDraft = {
  id: null,
  conversationId: null,
  campaignId: null,
  content: '',
  subject: null,
  attachments: [],
};

export const initialState = {
  from: 'messaging',
  currentCampaignId: null,
  searchQuery: '',
  filterByStatusId: '',
  profiles: {
    total: 0,
    page: 0,
    perPage: 20,
    hasMore: true,
    rows: [],
    filteredRows: [],
  },
  inbox: {
    total: 0,
    page: 0,
    perPage: 20,
    readStatus: 'all',
    hasMore: true,
    loaded: false,
    reload: false,
    allUnreadMessagesCount: 0,
    projectUnreadMessagesCount: 0,
    rows: [],
  },
  draft: {
    total: 0,
    page: 0,
    perPage: 20,
    hasMore: true,
    rows: [],
  },
  compose: {
    active: false,
    campaign: null,
    recipient: [],
  },
  conversation: {
    id: null,
    projectId: null,
    projectName: null,
    messages: {
      hasMore: true,
      page: 0,
      perPage: 10000,
      total: 0,
      rows: [],
    },
    draft: emptyDraft
  },
  profileDetails: {
    show: false,
    id: null,
  },
  templates: [],
  campaigns: {
    total: 0,
    page: 0,
    perPage: 20,
    hasMore: true,
    rows: [],
    filteredRows: [],
  },
  gmailAddress: null,
  toggleCreateMessage: false,
  displayPanel: true, // Move panel on mobile,
  displayCampaign: false,
  preview: {
    content: '',
    subject: '',
  },
};

const reducers = {
  [MESSAGING.LOAD_MESSAGES]: (state, { payload: {
    results, page, perPage, total, search, gmailAddress, projectUnreadMessagesCount, sendingMethod
  } }) => {
    // inbox
    state.inbox.total = total;
    state.inbox.projectUnreadMessagesCount = projectUnreadMessagesCount;
    state.inbox.page = !page ? 0 : page;
    if (perPage) state.inbox.perPage = perPage;
    state.gmailAddress = gmailAddress;
    state.sendingMethod = sendingMethod;
    state.inbox.hasMore = (state.inbox.page + 1) < Math.ceil(state.inbox.total / state.inbox.perPage);
    if (page === 0) state.inbox.loaded = true;
    if (search || page === 0 || !page) {
      // Get conversations from websocket
      const conv_from_ws = state.inbox.rows
        .filter(({ from_ws }) => !!from_ws)
        // that are not in the new results
        .filter(
          ({ id }) => !results.find(({ id: result_id }) => result_id === id),
        );

      state.inbox.rows = _orderBy(
        [...conv_from_ws, ...results],
        ['lastMessageAt', 'id'],
        'desc',
      );
    }
    if (page !== 0 && !search) state.inbox.rows.push(...new Set(results));
  },

  [MESSAGING.LOAD_MESSAGE]: (state, { payload: { projectUnreadMessagesCount, perPage, ...data } }) => {
    if (projectUnreadMessagesCount) state.inbox.projectUnreadMessagesCount = projectUnreadMessagesCount;
    state.conversation = { ...state.conversation, ...data };
    state.compose.active = true
  },

  [MESSAGING.LOAD_CONVERSATION_MESSAGE]: (state, { payload: { page, perPage, total, rows } }) => {
    if (total) state.conversation.messages.total = total;
    if (perPage) state.conversation.messages.perPage = perPage;
    state.conversation.messages.page = !page ? 0 : page;
    state.conversation.messages.hasMore = (state.conversation.messages.page + 1) < Math.ceil(state.conversation.messages.total / state.conversation.messages.perPage);
    state.conversation.messages.rows = _sortBy(
      _uniqBy((page === 0 || !page) ? rows : [
        ...state.conversation.messages.rows,
        ...rows
      ], 'id'),
      date => new Date(date.createdAt)
    )
  },

  [MESSAGING.CREATE_MESSAGE]: state => {
    state.searchQuery = '';
    state.filterByStatusId = '';
    state.compose.campaign = null;
    state.compose.recipient = [];
    state.conversation = initialState.conversation;
    if (state.toggleCreateMessage) state.compose = initialState.compose;
    if (!state.toggleCreateMessage) state.compose.active = true;
    if (state.displayCampaign) state.displayCampaign = false;
    state.toggleCreateMessage = !state.toggleCreateMessage;
  },

  [MESSAGING.RELOAD_MESSAGE]: (state, { payload: reload }) => {
    state.inbox.reload = reload;
  },

  [MESSAGING.CLEAR_MESSAGE]: state => {
    state.conversation = {
      ...initialState.conversation,
      draft: state.conversation.draft
    };
  },

  [MESSAGING.RESET_MESSAGE]: state => {
    state.conversation = initialState.conversation;
    state.compose = initialState.compose;
    state.currentCampaignId = '';
    state.searchQuery = '';
    state.filterByStatusId = '';
    state.displayPanel = true;
    state.toggleCreateMessage = false;
    state.inbox.readStatus = initialState.inbox.readStatus;
  },

  [MESSAGING.UPDATE_INBOX_WEBSOCKET]: (state, { payload: { message: _message, action } }) => {
      const getIndex = state.inbox.rows.findIndex(({ id }) => id === _message.id);
      const message = { ..._message, from_ws: true };
      if (getIndex !== -1) state.inbox.rows[getIndex] = message;
      if (getIndex === -1 && action !== 'message_read') state.inbox.rows.unshift(message);
  },

  [MESSAGING.SORT_INBOX_LIST]: state => {
    state.inbox.rows = _orderBy(state.inbox.rows, ['lastMessageAt', 'id'], 'desc');
  },

  [MESSAGING.CONVERSATION_MESSAGE_UPDATE]: (
    state,
    { payload: { conversation_id, ...message } },
  ) => {
    if (Number(conversation_id) === Number(state.conversation.id)) {
      state.conversation.messages.rows.push(message);
    }
  },

  [MESSAGING.UP_NEW_MESSAGE]: state => {
    state.toggleCreateMessage = false;
    state.searchQuery = '';
    state.filterByStatusId = '';
    state.displayPanel = true;
    state.compose.recipient = [];
  },

  [MESSAGING.LOAD_DRAFTS]: (state, { payload: { results, page, perPage, total, search } }) => {
    state.draft.total = total;
    state.draft.page = !page ? 0 : page;
    if (perPage) state.draft.perPage = perPage;
    state.draft.hasMore = (state.draft.page + 1) < Math.ceil(state.draft.total / state.draft.perPage);
    if (search || page === 0 || !page) state.draft.rows = _uniqBy(results, 'id');
    if ((page && page !== 0) && !search) state.draft.rows.push(...new Set(results));
  },

  [MESSAGING.LOAD_DRAFT]: (state, { payload: { id, content, recipient, subject, attachments, campaignId, conversationId }}) => {
    state.conversation.draft = { id, content, subject, attachments, campaignId, conversationId };
    if (recipient || campaignId) {
      const kolRecipients  = recipient.filter(({ type }) => type !== PROFILE_TYPES.contact);
      state.compose = {
        recipient: kolRecipients,
        campaign: campaignId,
        active: true
      };
    }
  },

  [MESSAGING.DELETE_DRAFT]: (state, { payload: draftId }) => {
    // Remove Inbox preview
    state.draft.rows = state.draft.rows.filter(({ id }) => id !== draftId);
    const getCardMessageIndex = state.inbox.rows.findIndex( conversation => conversation.draft?.id === draftId);
    if (getCardMessageIndex > -1) state.inbox.rows[getCardMessageIndex].draft = emptyDraft;

    // Remove from input
    if (state.conversation?.draft?.id === draftId) state.conversation.draft = emptyDraft;
  },

  [MESSAGING.UPDATE_DRAFT]: (state, { payload: draftUpdated }) => {
    // Update Inbox preview
    const getCardMessageIndex = state.inbox.rows.findIndex( conversation => conversation.id === draftUpdated.conversationId);
    if (getCardMessageIndex > -1) {
      state.inbox.rows[getCardMessageIndex].draft = draftUpdated;
    }
    const getCardDraftIndex = state.draft.rows.findIndex( draft => draft.conversationId === draftUpdated.conversationId);
    if (getCardDraftIndex > -1) {
      state.draft.rows[getCardDraftIndex] = draftUpdated;
    } else {
      // new draft
      state.draft.rows = [draftUpdated];
    }

    // Update input
    state.conversation.draft = draftUpdated;
  },

  [MESSAGING.SET_CAMPAIGN]: (state, { payload: campaignId }) => {
    state.compose.campaign = state.compose.campaign === campaignId ? '' : campaignId;
  },

  [MESSAGING.RESET_CAMPAIGN]: state => { state.compose.campaign = '' },

  [MESSAGING.LOAD_PROFILES]: (state, { payload: { results, page, perPage, search } }) => {
    state.profiles.total = results.total;
    state.profiles.page = !page ? 0 : page;
    if (perPage) state.profiles.perPage = perPage;
    state.profiles.hasMore = (state.profiles.page + 1) < Math.ceil(state.profiles.total / state.profiles.perPage);
    if (search || page === 0 || !page) state.profiles.rows = results.rows
    if (page !== 0 && !search) state.profiles.rows.push(...new Set(results.rows));
  },

  [MESSAGING.LOAD_CAMPAIGN]: (state, { payload: { results, page, perPage, total, search } }) => {
    state.campaigns.total = total;
    state.campaigns.page = !page ? 0 : page;
    if (perPage) state.campaigns.perPage = perPage;
    state.campaigns.hasMore = (state.campaigns.page + 1) < Math.ceil(state.campaigns.total / state.campaigns.perPage);
    if (search || page === 0 || !page) state.campaigns.rows = results;
    if (page !== 0 && !search) state.campaigns.rows.push(...new Set(results));
  },

  [MESSAGING.HANDLE_SELECTION]: (state, { payload: { id, name, fullName, pseudo, avatar, contactableEmail, type }}) => {
    if (type === 'reset') state.compose.recipient = initialState.compose.recipient;
    if (type === 'check') state.compose.recipient.push({ id, name, fullName, pseudo, avatar, contactableEmail });
    if (type === 'uncheck') state.compose.recipient = state.compose.recipient.filter(item => item.id !== id);
    if (type === 'checkedAll') state.compose.recipient.push(state.draft.profile);
  },

  [MESSAGING.SET_RECIPIENTS]: (state, { payload: recipients}) => {
    state.compose.recipient = recipients
  },

  [MESSAGING.DISPLAY_PROFILE]: (state, { payload: { profileId, displayDetails }}) => {
    state.profileDetails.id = profileId;
    state.profileDetails.show = displayDetails;
  },

  [MESSAGING.HANDLE_VIEW]: (state, { payload: { displayPanel }}) => { state.displayPanel = displayPanel },

  [MESSAGING.TOGGLE_KOL_TO_CAMPAIGN]: state => {
    state.compose.campaign = '';
    state.displayCampaign = !state.displayCampaign;
  },

  [MESSAGING.TEMPLATES_LOADED]: (state, { payload: templates }) => { state.templates = templates },
  [MESSAGING.TEMPLATE_DELETED]: (state, { payload: id }) => { state.templates = state.templates.filter(template => template.id !== id) },

  [MESSAGING.GET_FROM]: (state, { payload: from }) => { state.from = from },

  [MESSAGING.READ_STATUS]: (state, { payload: status }) => { state.inbox.readStatus = status },

  [MESSAGING.SET_GMAIL_ADDRESS]: (state, { payload: gmailAddress }) => { state.gmailAddress = gmailAddress },

  [MESSAGING.SEARCH_QUERY]: (state, { payload: query }) => { state.searchQuery = query },

  [MESSAGING.SET_STATUS_FILTER]: (state, { payload: statusId }) => { state.filterByStatusId = statusId },

  [MESSAGING.SET_ALL_UNREAD_MESSAGES]: (state, { payload: allUnreadMessagesCount }) => {
    state.inbox.allUnreadMessagesCount = allUnreadMessagesCount;
  },

  [MESSAGING.SET_CURRENT_CAMPAIGN_ID]: (state, { payload }) => { state.currentCampaignId = payload },

  // Hack to update subject and subject threads without recall
  // Waiting for websocket
  [MESSAGING.SET_NEW_SUBJECT]: (state, { payload: { subject, subjectThreads } }) => {
    state.conversation.subject = subject;
    state.conversation.subjectThreads = subjectThreads;
  },

  // Message preview
  [MESSAGING.SAVE_PREVIEW]: (state, { payload: { content } }) => {
    state.preview.content = content;
  },
  [MESSAGING.CLEAR_PREVIEW]: (state) => {
    state.preview = initialState.preview;
  },
};

export default createReducer(initialState, reducers);
