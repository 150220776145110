import React from 'react';
import { FormattedMessage } from 'react-intl';

import Icon from 'kolkit/Icon';
import Typography from 'components/ui/Typography';

const CodeVerificationSuccess = () => {
  return (
    <div className="flex fdc gap16 aic">
      <Icon
        label="shield-check"
        theme="thin"
        fill="var(--color-alert-success)"
        width={64}
      />
      <div className="flex fdc aic">
        <Typography variant="title/page-title" className="text-capitalize">
          <FormattedMessage id="auth.doubleAuthentication.modal.success.text" />
        </Typography>
        <Typography variant="title/subtitle">
          <FormattedMessage id="auth.doubleAuthentication.modal.success.description" />
        </Typography>
      </div>
    </div>
  );
};

export default CodeVerificationSuccess;
