import * as featureNames from 'constants/feature-names';
import { isFeatureActive } from 'utils/feature-toggle';

const routes = {
  // Search engine (index)

  // My KOLs
  myKOLs: `my-kols`,
  myInfluencers: `my-influencers`, // old one, should redirect to myKOLs

  // My KOLs tabs
  manage: `manage`,
  list: `list`,
  customFields: `custom-fields`,
  manageLabels: 'manage-tags',
  follow: `follow`,

  // Campaign list
  campaigns: `projects`,

  // Campaign details
  campaignPayment: `payment`,
  reporting: `monitor`,
  discountCode: 'discount-code',
  trackingLinks: 'tracking-links',
  roi: 'roi',

  // Messaging
  messaging: 'messaging',

  // Dashboard
  dashboard: 'dashboard',
  keyMetricsDashboard: 'key-metrics',
  listening: 'listening',
  followersIncrease: 'followers-increase',

  // Settings
  myAccount: `account`,
  myAccountInfos: 'infos',
  myAccount_integrations: 'integrations',
  myAccount_organization: 'organization',
  paymentContact: 'payment-contact',

  // Profile details
  profile: 'profiles',

  // Public pages
  publicReport: `p/report`,
  publicCasting: 'p/castings',
  publicContentValidation: 'p/content-validation',

  // Login & register page
  login: 'login',
  register: 'register',

  // Debug page (not in production)
  debug: 'debug',

  // Zendesk
  zendesk: 'zendesk',

  // Content search
  search: 'search',

  // Content search
  contentSearch: 'content-search',
};

export const getRedirectUrl = () => {
  switch (true) {
    case isFeatureActive(featureNames.SEARCH_ENGINE):
      return '/';
    case isFeatureActive(featureNames.MANAGE_CAMPAIGN):
      return `/${routes.campaigns}`;
    case isFeatureActive({
      any: [featureNames.FAVORITES, featureNames.LABELS],
    }):
      return `/${routes.myKOLs}`;
    case isFeatureActive(featureNames.LISTENING):
      return `/${routes.listening}`;
    default:
      return `/${routes.myAccount}`;
  }
};

export default routes;
