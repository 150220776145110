import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import formatPhoneNumberWithObfuscation from 'utils/formatPhoneNumberWithObfuscation';

import { Input2, Text } from 'kolkit';

type Props = {
  phoneNumber: string;
  onChangeVerificationCode: (param: { value: string }) => void;
  error?: string;
};

const CodeVerificationForm = ({
  phoneNumber,
  onChangeVerificationCode,
  error,
}: Props) => {
  const intl = useIntl();

  return (
    <div className="flex fdc gap8">
      <Text resetMargin>
        <FormattedMessage
          id="auth.doubleAuthentication.modal.verificationCode.label"
          values={{
            phoneNumber: formatPhoneNumberWithObfuscation(phoneNumber),
          }}
        />
      </Text>
      <Input2
        autoFocus
        size="large"
        fullWidth
        placeholder={intl.formatMessage({
          id: 'auth.doubleAuthentication.modal.setup.placeholder',
          defaultMessage: 'xx xx xx xx',
        })}
        onChange={onChangeVerificationCode}
        error={!!error}
        errorMessage={error || ''}
      />
    </div>
  );
};

export default CodeVerificationForm;
