import memoize from 'memoizee';

import { USER_ROLES } from 'constants/states';

export const getUserRightsOnSelection = memoize(role => {
	// Panel of all rights at campaign level
	const rights = {
		edit: false,					// Panel campaign
		remove: false,					// Delete campaign
		archive: false,					// Archive campaign
		moveToFolder: false,			// Archive campaign
		addInfluencer: false,			// Add influencer to campaign
		removeInfluencer: false,		// Remove influencer from campaign
		exportInfluencers: false,		// Export influencers list
		updateInfluencerState: false,	// Update influencer state un campaign
		updateRights: false,			// Update user rights at campaign level
		showDiscuss: false,				// Show pane Discuss
		sendMessage: false,				// Send a message in discuss
		startCampaign: false,			// Inbox Campaign (in "auto" mode)
		showReporting: false,			// Show pane Reporting
		updateReporting: false,			// Update reporting datas
		exportReporting: false,			// Export reporting datas
		editInteractions: false,		// Show Panel button in post interactions
		filterReportingByTag: false,	// Show user's tags filter in campaign reporting
	}

	const getAllowedActionsByRole = () => {
		switch (role) {
			case USER_ROLES.OWNER:
				return Object.keys(rights).reduce((p, c) => Object.assign(p, {[c]: true}), {}); // All rights
			case USER_ROLES.EDITOR: // All rights except "moveToFolder"
				return Object.keys(rights)
					.filter(r => !['moveToFolder'].includes(r))
					.reduce((p, c) => Object.assign(p, {[c]: true}), {});
			case USER_ROLES.ADMINBC:
				return {
					edit: true,
					addInfluencer: true,
					removeInfluencer: true,
					exportInfluencers: true,
					updateInfluencerState: false,
					updateRights: true,
					showDiscuss: true,
					startCampaign: true,
					showReporting: true,
					updateReporting: true,
					exportReporting: true,
					editInteractions: true,
					filterReportingByTag: true
				};
			case USER_ROLES.VIEWER:
				return {
					exportInfluencers: true,
					showDiscuss: true,
					showReporting: true,
					exportReporting: true,
					filterReportingByTag: true,
				};
			case "test-dev":
			return {
				showDiscuss: true,
				showReporting: true,
				updateRights: true,
				filterReportingByTag: true,
			}
			default:
				return {}
		}
	};

	const allowedActions = getAllowedActionsByRole();

	return { ...rights, ...allowedActions };
});

export const getUserRightsOnTeam = memoize(role => {

	const rights = {
		createCampaign: false,		// Create a new campaign
		updateRights: false,		// Update team members rights
		updateEmail: false,			// Update email
	}

	const getAllowedActionsByRole = () => {
		switch (role) {
			case USER_ROLES.OWNER:
			case USER_ROLES.EDITOR:
				return Object.keys(rights).reduce((p, c) => Object.assign(p, {[c]: true}), {}); // All rights
			case USER_ROLES.VIEWER:
			default:
				return {}
		}
	};

	const allowedActions = getAllowedActionsByRole();

	return { ...rights, ...allowedActions };
});
