import { post, get, put } from 'api';
import { ApiPromise } from 'api/types';

import { DataParameters, DataFromApi } from './nsmDashboard.types';

export const saveSetup: (
  mentions: { mention: string; network: string }[],
) => ApiPromise<any> = (mentions) =>
  post({
    endpoint: 'influence/v2/lighthouse',
    params: {
      social_network_mentions: mentions.reduce((prev, curr, index) => ({
        ...prev,
        [index]: curr,
      }), {}),
    },
  });

export const updateSetup: (
  mentions: { mention: string; network: string }[],
) => ApiPromise<any> = (mentions) =>
  put({
    endpoint: 'influence/v2/lighthouse',
    params: {
      social_network_mentions: mentions.reduce((prev, curr, index) => ({
        ...prev,
        [index]: curr,
      }), {}),
    },
  });

export const loadData: (
  params?: DataParameters,
) => ApiPromise<DataFromApi> = (params) =>
  get({
    endpoint: 'influence/v2/lighthouse',
    params: params || {},
    catchErrors: [404], // 404: Setup not yeat configured
  });
