import React, {
  ComponentProps,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import useMeasure from 'react-use-measure';
import ResizeObserver from 'resize-observer-polyfill';
import { useIntl } from 'react-intl';
import { useTransition, animated } from 'react-spring';
import { Value } from 'react-phone-number-input';

import { Modal2 } from 'kolkit';
import useModal from 'utils/hooks/useModal';
import renderModal from 'utils/HOCs/renderModal';

import InputPhoneWithCountrySelector, {
  isPossiblePhoneNumber,
} from 'components/ui/InputPhoneWithCountrySelector';
import { useDispatch, useSelector } from 'utils/redux';
import {
  login,
  setupDoubleAuthentication,
  validateDoubleAuthentication,
} from 'actions/auth';
import { userParamsProfileLoaded } from 'actions/user';

import Methods from './Methods';
import ByAppStep from './ByAppStep';

import CodeVerificationForm from './views/CodeVerificationForm';
import CodeVerificationSuccess from './views/CodeVerificationSuccess';

import {
  DoubleAuthenticationStep,
  DOUBLE_AUTHENTICATION_MODAL_ID,
  STEPS_BY_APP,
  STEPS_BY_SMS,
} from './constants';

import styles from './DoubleAuthentication.module.scss';

const OTP_CODE_LENGTH = 6;

const INITIAL_SETUP_STATE = {
  method: 'phone',
  isPossible: false,
  phoneNumber: '',
};

const SetupDoubleAuthenticationModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [slideItemRef, slideItemSize] = useMeasure({ polyfill: ResizeObserver });

  const {
    show,
    off,
    data: { step, phoneNumber, otp_user_id, otp_method },
  } = useModal<{
    step: DoubleAuthenticationStep;
    otp_method: string;
    phoneNumber?: Value;
    otp_user_id?: number;
  }>(DOUBLE_AUTHENTICATION_MODAL_ID);

  const isUserLoggedIn = useSelector(
    ({
      user: {
        profile: { id },
      },
    }) => !!id,
  );

  // Step CODE_VERIFICATION
  const [verificationCode, setVerificationCode] = useState({
    value: '',
    loading: false,
  });
  const [error, setError] = useState('');
  const [noSteps, setHideStepTag] = useState(false);

  // Step SETUP
  const [setupValue, setSetupValue] = useState(INITIAL_SETUP_STATE);

  const [slideIndex, setSlideIndex] = useState(0);
  const [direction, setDirection] = useState('left');
  const [qrCodeState, setQrCodeState] = useState({
    loading: false,
    qrCode: '',
  });

  const showErrorMessage = (intl, error: string | Error) => {
    if (typeof error === 'string') {
      return intl.formatMessage({
        id: `auth.doubleAuthentication.modal.error.${error}`,
      });
    }
    console.error('Error while validating 2FA code :>> ', error.message);
    return intl.formatMessage({
      id: `auth.doubleAuthentication.modal.error.other`,
    });
  };

  const handleChangePhoneNumber = useCallback<
    ComponentProps<typeof InputPhoneWithCountrySelector>['onChange']
    >(({ isPossible, value }) => {
    setSetupValue(s => ({ ...s, isPossible, phoneNumber: value?.trim() }));
  }, []);

  useEffect(() => {
    if (phoneNumber) {
      setSetupValue(s => ({ ...s, phoneNumber }));
    }
  }, [phoneNumber])

  const handleClose = useCallback(
    () => {
      off();
      if (
        (setupValue.method === 'phone' && slideIndex === 3) ||
        (setupValue.method === 'authenticator' && slideIndex === 4)
      ) {
        // Update the user profile otp data on close thanks step
        dispatch(userParamsProfileLoaded({
          otp_required_for_login: true,
          otp_method: setupValue.method,
        }));
      }
    },
    [off, slideIndex, setupValue.method, dispatch]
  );

  const handleNext = useCallback(
    () => {
      setSlideIndex(index => index + 1);
      setDirection('right');
    },
    []
  );

  const handlePrevious = useCallback(
    () => {
      setSlideIndex(index => index - 1);
      setDirection('left');
      setHideStepTag(false);
    },
    []
  );

  const handleMethod = useCallback(
    (value) => {
      setSetupValue(s => ({ ...s, method: value }));
      handleNext();
    },
    [handleNext]
  );

  const handleSubmitVerificationCode = useCallback(
    (code?: string) => {
      setVerificationCode(c => ({ ...c, loading: true }));
      void dispatch(
        isUserLoggedIn
          ? validateDoubleAuthentication(
              Object.assign(
                {
                  otp_attempt: code || verificationCode.value,
                },
                otp_user_id && { otp_user_id },
              ),
            )
          : login({ otp_user_id, otp_attempt: code || verificationCode.value }),
      ).then((response) => {
        setVerificationCode(c => ({ ...c, loading: false }));
        if (response.error) {
          setError(response.error_code || response.error);
        } else if (isUserLoggedIn) {
          handleNext();
        } else {
          handleClose();
        }
      });
    },
    [
      verificationCode.value,
      isUserLoggedIn,
      otp_user_id,
      handleClose,
      handleNext,
      dispatch,
    ]
  );

  const handleChangeVerificationCode = useCallback(
    ({ value }) => {
      setError('');
      setVerificationCode(c => ({ ...c, value }));
      if (value.length === OTP_CODE_LENGTH) {
        handleSubmitVerificationCode(value);
      }
    },
    [handleSubmitVerificationCode]
  );

  const slides = {
    phone: [
      {
        key: 0,
        component: <Methods onChange={handleMethod} />,
      },
      {
        key: 1,
        component: (
          <InputPhoneWithCountrySelector
            onChange={handleChangePhoneNumber}
            value={setupValue.phoneNumber as Value}
            error={error ? showErrorMessage(intl, error) : ''}
            // defaultPhoneNumber={phoneNumber}
          />
        ),
      },
      {
        key: 2,
        component:
          <CodeVerificationForm 
            phoneNumber={phoneNumber || setupValue.phoneNumber}
            onChangeVerificationCode={handleChangeVerificationCode}
            error={error 
              ? showErrorMessage(intl, error) 
              : ''
            }
          />
      },
      {
        key: 3,
        component: <CodeVerificationSuccess />,
      },
    ],
    authenticator: [
      {
        key: 0,
        component: <Methods onChange={handleMethod} />,
      },
      {
        key: 1, // Download app step
        component: <ByAppStep step={slideIndex - 1} />
      },
      {
        key: 2, // Show QR code step
        component: <ByAppStep
          step={slideIndex - 1}
          loading={qrCodeState.loading}
          base64Image={qrCodeState.qrCode}
        />
      },
      {
        key: 3, // Enter one-time code step
        component: <ByAppStep
          step={slideIndex - 1}
          loading={verificationCode.loading}
          hideStepTag={noSteps}
          input={{
            onChange: handleChangeVerificationCode,
            error,
          }}
        />
      },
      {
        key: 4,
        component: <CodeVerificationSuccess />,
      },
    ]
  }

  const transitions = useTransition(slides[setupValue.method]?.[slideIndex], slide => slide?.key, {
    from: {
      opacity: 0,
      transform: `translate3d(${direction === 'right' ? 100 : -100}%, 0, 0)`,
    },
    enter: { opacity: 1, transform: 'translate3d(0%, 0, 0)' },
    leave: {
      opacity: 0,
      transform: `translate3d(${direction === 'right' ? -100 : 100}%, 0, 0)`,
    },
  });

  const loadQrCode = useCallback(
    () => {
      setQrCodeState({ loading: true, qrCode: '' });
      void dispatch(
        setupDoubleAuthentication(
          Object.assign(
            {
              method: setupValue.method,
              active: true,
            },
            otp_user_id && { otp_user_id },
          ),
        ),
      ).then((response) => {
        setQrCodeState({ loading: false, qrCode: response?.qr_code_png });
      });
    },
    [setupValue.method, otp_user_id, dispatch]
  );

  const actions = useMemo(() => {
    if (slideIndex === 0) return undefined; // No actions for the first slide (2FA method selection)

    if (setupValue.method === 'phone') {
      switch (slideIndex) {
        case 1: { // Enter phone number step
          return {
            primary: {
              title: intl.formatMessage({ id: 'global.cta.next' }),
              disabled: !setupValue.isPossible,
              onClick: () => {
                void dispatch(
                  setupDoubleAuthentication(
                    Object.assign(
                      {
                        method: setupValue.method,
                        phoneNumber: setupValue.phoneNumber,
                        active: true,
                      },
                      otp_user_id && { otp_user_id },
                    ),
                  ),
                ).then((response) => {
                  if (response.error) {
                    setError(response.error_code || response.error);
                  } else {
                    handleNext();
                  }
                });
              },
            },
            secondary: {
              title: intl.formatMessage({ id: 'global.cta.previous' }),
              onClick: () => {
                handlePrevious();
              },
            },
          };
        }
        case 2: { // Enter verification sms code step
          return {
            primary: {
              title: intl.formatMessage({ id: 'global.cta.validate' }),
              disabled:
                verificationCode.value?.trim().length !== OTP_CODE_LENGTH,
              onClick: handleSubmitVerificationCode,
            },
            ...(isUserLoggedIn
              ? {
                  secondary: {
                    title: intl.formatMessage({ id: 'global.cta.previous' }),
                    onClick: () => {
                      handlePrevious();
                    },
                  },
                }
              : {}),
          };
        }
        case 3: { // Success step
          return {
            primary: {
              title: intl.formatMessage({ id: 'global.cta.close' }),
              onClick: () => {
                handleClose();
              },
            },
          };
        }
        default:
          return undefined;
      }
    }

    if (setupValue.method === 'authenticator') {
      switch(slideIndex) {
        case 1: return { // Download app step
          primary: {
            title: intl.formatMessage({ id: 'global.cta.next' }),
            onClick: () => {
              handleNext();
              loadQrCode();
            }
          },
          secondary: {
            title: intl.formatMessage({ id: 'global.cta.previous' }),
            onClick: handlePrevious,
          }
        }
        case 2: return {  // Show QR code step
          primary: {
            title: intl.formatMessage({ id: 'global.cta.next' }),
            disabled: qrCodeState.loading,
            onClick: () => {
              handleNext()
            },
          },
          secondary: {
            title: intl.formatMessage({ id: 'global.cta.previous' }),
            disabled: qrCodeState.loading,
            onClick: () => {
              setQrCodeState({ loading: false, qrCode: '' });
              handlePrevious();
            },
          }
        }
        // Enter one-time code step
        case 3: {
          return {
            primary: {
              title: intl.formatMessage({ id: 'global.cta.validate' }),
              disabled:
                verificationCode.value?.trim().length !== OTP_CODE_LENGTH ||
                verificationCode.loading,
              onClick: handleSubmitVerificationCode,
            },
            ...(isUserLoggedIn
              ? {
                  secondary: {
                    title: intl.formatMessage({ id: 'global.cta.previous' }),
                    disabled: verificationCode.loading,
                    onClick: () => {
                      handlePrevious();
                      if (!qrCodeState.qrCode) {
                        loadQrCode();
                      }
                    },
                  },
                }
              : {}),
          };
        }
        case 4: return { // Success step
          primary: {
            title: intl.formatMessage({ id: 'global.cta.close' }),
            onClick: () => {
              handleClose();
            },
          }
        }
        default:
          return undefined;
      }
    }

    return undefined;
  }, [
    slideIndex,
    isUserLoggedIn,
    handleNext,
    handlePrevious,
    qrCodeState,
    loadQrCode,
    intl,
    setupValue,
    dispatch,
    handleClose,
    verificationCode,
    handleSubmitVerificationCode,
    otp_user_id,
  ]);

  useEffect(() => {
    if (otp_method === 'phone') {
      setSetupValue(s => ({ ...s, method: 'phone' }));
      if (step) {
        setHideStepTag(true);
        setSlideIndex(STEPS_BY_SMS.indexOf(step));
      }
      if (phoneNumber) {
        setSetupValue((prev) => ({
          ...prev,
          phoneNumber,
          isPossible: isPossiblePhoneNumber(phoneNumber),
        }));
      }
    }

    if (otp_method === 'authenticator') {
      setSetupValue(s => ({ ...s, method: 'authenticator' }));
      if (step) {
        setHideStepTag(true);
        setSlideIndex(STEPS_BY_APP.indexOf(step));
      }
    }
  }, [phoneNumber, step, otp_method]);

  const title = useMemo(
    () => {
      if (slideIndex === 0)
        return intl.formatMessage({ id: 'auth.doubleAuthentication.modal.method.title' });

      if (setupValue.method === 'phone')
        return slideIndex === 3
          ? intl.formatMessage({ id: 'auth.doubleAuthentication.modal.success.title' })
          : intl.formatMessage({ id: 'auth.doubleAuthentication.modal.bySms.title' })

      if (setupValue.method === 'authenticator')
        return slideIndex === 4
          ? intl.formatMessage({ id: 'auth.doubleAuthentication.modal.success.title' })
          : intl.formatMessage({ id: 'auth.doubleAuthentication.modal.byApp.title' })

      return '';
    },
    [slideIndex, setupValue.method, intl]
  );

  const subTitle = useMemo(
    () => {
      if (slideIndex === 0)
        return intl.formatMessage({ id: 'auth.doubleAuthentication.modal.method.subTitle' });

      // TODO: custom subtitle for each step if needed
      return '';
    },
    [slideIndex, intl]
  );

  return (
    <Modal2
      on={show}
      onClick={handleClose}
      title={title}
      subTitle={subTitle}
      action={actions}
    >
      <div className={styles.slider} style={{ height: `${slideItemSize?.height}px`}}>
        {transitions.map(({ item, props, key }) => {
          return (
            <animated.div
              key={key}
              style={props}
            >
              <div className={styles.slide} ref={slideItemRef}>
                {item?.component}
              </div>
            </animated.div>
          );
        })}
      </div>
    </Modal2>
  );
};

export default renderModal(DOUBLE_AUTHENTICATION_MODAL_ID, SetupDoubleAuthenticationModal);
