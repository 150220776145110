import { createAction } from 'redux-actions';
import Cookies from 'universal-cookie';
import { FullStory } from '@fullstory/browser';
import has from 'lodash.has';

import { delayedAction, setControlledLoader } from 'actions/ui';
import { planhatTraker } from 'utils/tags';
import history from 'config/history';
import { getLocaleById } from 'locales';
import * as USER from 'constants/user';
import * as ENV from 'constants/env';
import currencies from 'config/currencies';
import { conf, env, ENV_PROD } from 'config/env';

import { USER_ROLES } from 'constants/userRoles';
import { fetchTeamsAccess } from 'slices/organization.slice';

import { setClientToken } from './env';
import { prepareSearch, resetFilters as reset } from './engine';
import * as affilaeActions from './affilae';
import * as campaignsActions from './campaigns';
import * as profilesMyInfluencers from './myInfluencers';
import * as api from '../api/user';
import * as mapper from '../mappers/user';


export const userParamsLoading = createAction(USER.PARAMS_LOADING);
export const userParamsLoaded = createAction(USER.PARAMS_LOADED);
export const userParamsProfileLoaded = createAction(USER.PROFILE_PARAMS_LOADED);
export const userUpdate = createAction(USER.UPDATE);
export const userInfoUpdate = createAction(USER.INFO_UPDATE);
export const userEmailUpdate = createAction(USER.EMAIL_UPDATE);

export const userLicenceUpdate = createAction(USER.LICENCE_UPDATE);

export const resetAndUpdateFilters = createAction(USER.RESET_UPDATE_FILTERS);
export const resetNetworkFilters = createAction(USER.RESET_NETWORK_FILTERS);
export const resetAllNetworkFilters = createAction(USER.RESET_ALL_NETWORK_FILTERS);

export const updateFiltersInStore = createAction(USER.UPDATE_FILTERS);
export const updateFilterInStore = createAction(USER.UPDATE_FILTER);

export const activeFilter = createAction(USER.ACTIVE_FILTER);
export const updateSelectedFilter = createAction(USER.SELECTED_FILTER);

export const updateUserState = createAction(USER.UPDATE_STATE);
export const updateNetworkFilterInStore = createAction(USER.UPDATE_FILTER_NETWORK);
export const updateInfluencerFilterInStore = createAction(USER.UPDATE_FILTER_INFLUENCER);

// not reset filters but reset Search
export const resetFiltersInStore = createAction(USER.RESET_FILTERS);

export const resetFiltersWithoutSearch = createAction(USER.RESET_FILTERS_WHITOUT_SEARCH);

export const setTags = createAction(USER.TAGS_SET);

export const sendingMethodAction = createAction(USER.SENDING_METHOD);
export const hideGmailConnectAction = createAction(USER.HIDE_GMAIL_CONNECT);
export const sendingUserPreferences = createAction(USER.SET_USER_UNACKOWNLEDGE_FEATURES);

export const saveIntegrations = createAction(USER.SAVE_INTEGRATIONS)
export const updateIntegrations = createAction(USER.UPDATE_INTEGRATIONS)

export const updateEnvKey = createAction(ENV.UPDATE_KEY);

export const clearInitialBannerMessage = createAction(USER.CLEAR_INITIAL_BANNER_MESSAGE);

export const getUserLicence = () => async (dispatch, getState) => {
  const {
    user: {
      profile: { admin },
    },
  } = getState();
  if (admin) return null;
  dispatch(setControlledLoader({ id: 'getUserLicence', show: true }));
  const licence = await api.getLicence();
  const paramsLicence = mapper.getUserLicence.fromApi(licence);

  dispatch(userLicenceUpdate({
		...paramsLicence,
		canExport: paramsLicence?.canExport
  }));
  dispatch(setControlledLoader({ id: 'getUserLicence', show: false }));
};

export const getUserParams = () => async (dispatch, getState) => {
	dispatch(userParamsLoading());

	const params = await api.appInit();

	if (!params) return false;

	const paramsMapped = mapper.getUserParams.fromApi(params);
	const hasAffilaeConnection = mapper.checkIfHasAffilaeConnection.fromApi(params);
	const canExport = getState().user.profile?.licence?.canExport || false;

	const {
		profile,
	  	isFromKolsquare,
		timezone: mappedTimezone,
		features: { manageCampaigns, tags: manageTags },
	} = paramsMapped;

	if (
		!isFromKolsquare ||
		/^(ludivine\.gibier|alix|alix\.dumarest|ore\.akinniranye|pierre-nicolas\.ngamby|vincent\.forest)(.+)?@(kolsquare|brandandcelebrities)\.com$/.test(
			profile.email,
		)
	) {
		FullStory('start');
	} else {
		FullStory('shutdown');
	}

	let timezone = mappedTimezone;

	if (!timezone || timezone === 'null') {
		const { default: momentTz } = await import('moment-timezone');
		timezone = momentTz.tz.guess(false); // false: ignoreCache
		dispatch(
			updateAreaSettings({
				key: 'timezone',
				data: timezone,
			}),
		);
	}

	dispatch(userParamsLoaded({
		...paramsMapped,
		timezone,
		canExport: canExport || profile.admin,
	}));

	await dispatch(getUserLicence());

	if (manageTags) await dispatch(getTags());

	if (manageCampaigns && !profile.admin) {
		await Promise.all([
			dispatch(getUserProjects()),
			dispatch(profilesMyInfluencers.getCustomFields()),
			dispatch(getIntegrations()),
			dispatch(getCategoryEmails())
		])
	}

	if (!profile?.admin) {
		await dispatch(profilesMyInfluencers.getCustomList());
	}

	if ([USER_ROLES.SUPER_USER, USER_ROLES.MANAGER].includes(profile?.userRole)) {
		dispatch(fetchTeamsAccess());
	}

	if (hasAffilaeConnection) {
		dispatch(affilaeActions.setHasAffilaeConnection(hasAffilaeConnection));
	}

	if (env === ENV_PROD) {
		const { user: { integrations, hasMonitorings } } = getState();

		await planhatTraker({
			action: 'integrations',
			info: {
				shopify: has(integrations, 'shopify_store'),
				gmail: has(integrations, 'gmail_account'),
				affilae: hasAffilaeConnection,
			}
		})
		await planhatTraker({
			action: 'rapports',
			info: {
				listening: hasMonitorings,
			}
		})
	}

	return true;
};

export const loadUserParamsUpdated = () => async (dispatch) => {
	const params = await api.appInit();
	if (params) {
		const { profile } = mapper.getUserParams.fromApi(params);
		dispatch(userParamsProfileLoaded(profile));
		await dispatch(getUserProjects());
		await dispatch(getUserLicence());
		if ([USER_ROLES.SUPER_USER, USER_ROLES.MANAGER].includes(profile?.userRole)) {
			dispatch(fetchTeamsAccess());
		}
	}
}

export const getUserProjects = status => async dispatch => {
	dispatch(setControlledLoader({ id: 'paneProfiles', show: true }));

	const res = await api.getProjects(status);

	const campaignsMapped = mapper.getProjects.fromApi(res.projects)

	dispatch(campaignsActions.batch(campaignsMapped))

	dispatch(setControlledLoader({ id: 'paneProfiles', show: false }));
};

export const logout = () => async (dispatch, getState) => {
	// clear session cookie
	document.cookie = `hideAnnouncement=; path=/`;
	new Cookies().remove(conf.cookieToken, {
		path: '/',
		domain: conf.cookieHostname,
		sameSite: true,
	});
	const { env : { locale }} = getState();
	const lang = getLocaleById(locale);
	await api.logout();
	dispatch(setClientToken(''));
	window.location.href = `${conf.urls.login}?lang=${lang?.iso2 || ''}`;
};

export const updateInfoRequest = (firstName, lastName, phone) => async dispatch => {
	const res = await api.updateInfo({ firstName, lastName, phone });

	if (res && res.error) return res;
	dispatch(userInfoUpdate({ firstName, lastName, phone }));
	return true;
};

export const updateEmailOnRequest = email => async dispatch => {
	const res = await api.updateEmail(email);
	if (res && res.error) return res;
	dispatch(userEmailUpdate(email));
	return true;
};

export const updatePasswordOnRequest = (password, newPassword, passwordConfirmation) => async dispatch => {
	const res = await api.updatePassword({password, newPassword, passwordConfirmation});

	if (res && res.error) return res;

	// Update token from cookies
	const cookies = new Cookies();
	const cookieToken = cookies.get(conf.cookieToken);
	dispatch(setClientToken(cookieToken));

	return true;
};

export const updateAreaSettings = ({ key, data }) => async (dispatch, getState) => {
	dispatch(setControlledLoader({ id: 'updateAreaSettings', show: true }));
  switch (key) {
    case 'currency': {
	  const { rates } = getState().env.currency;
      const res = await api.updateCurrency(data);
      if (!res || res.error) {
        console.error(
          `Could not update currency to ${data}. Reason: `,
          res?.error,
        );
      } else {
        const newCurrency = {
          value: data,
		  rates,
          ...currencies.find(curr => curr.value === data),
        };
        dispatch(updateEnvKey({ key: 'currency', data: newCurrency }));
        dispatch(userUpdate({ key: 'currency', data: newCurrency }));
      }
      break;
    }
    case 'timezone':
      await api.updateTimezone(data);
      dispatch(updateEnvKey({ key, data }));
      break;
    case 'dateFormat':
      await api.updateDateFormat(data);
      dispatch(updateEnvKey({ key, data }));
      break;
    default:
      break;
  }
  dispatch(setControlledLoader({ id: 'updateAreaSettings', show: false }));
  return true;
};

const DELAY = 500;

const delaySearch = () => {
	return delayedAction(prepareSearch(), DELAY)
};

export const unselectSna = data => dispatch => {
	dispatch(updateSelectedFilter(data));
	dispatch(resetAllNetworkFilters())
};

export const updateFilter = data => dispatch => {
	const {
		admin,
		field,
		action,
		doNotTriggerSearch,
		forceTriggerSearch,
		network,
		topSort
	} = data;

	// REFONTE PREVU NEXT SPRINT  - C'est pas beau actuellement mais ça fonctionne !
	if (network && network !== 'influencer' && field !== 'activeFilter') dispatch(updateNetworkFilterInStore(data));
	if ((network && network === 'influencer') || admin || topSort) dispatch(updateInfluencerFilterInStore(data));

	// action launch by modal filter button
	if (field === 'initFilter') dispatch(activeFilter(data));

	// remove sna from search and reset his filter
	if (field === 'activeFilter') {
		dispatch(updateSelectedFilter(data));
		dispatch(resetNetworkFilters(data));
	}

	if (action === 'resetFilterWithoutSearch') {
		dispatch(resetFiltersWithoutSearch(data));
	}

	// Do one search each DELAYms, not to spam this poor little back-end
	if (
		!doNotTriggerSearch
		&& (field === 'search' || field === 'initFilter' || forceTriggerSearch)
	) {
		dispatch(updateFilterInStore(data));
		dispatch(delaySearch());
	}
};

// NOT ONLY FILTER BUT ALL SEARCH - TO CHANGED
export const resetFilters = () => () => {
	history.push('/');
};

export const resetSearch = () => dispatch => {
	dispatch(resetFiltersInStore());
	dispatch(reset());
};

/** *******
 *
 * TAGS / LABELS
 */
export const getTags = () => async dispatch => {
	try {
		const res = await api.getTags();
		const tags = mapper.getTags.fromApi(res);
		dispatch(setTags(tags));
		return tags;
	} catch (e) {
		console.error('[get tags]', e)
	}
};

// Add tag to user
export const addTag = ({ tag }) => async (dispatch, getState) => {
	const res = await api.addTag({ tag });
	if (res.error) return res;

	const addedId = mapper.addTag.fromApi(res);

	const { tags: currentTags } = getState().user;

	const newTag = {
		id: addedId,
		value: tag
	};

	const newTags = [
		newTag,
		...currentTags
	];

	dispatch(setTags(newTags));

	return newTag;
};

export const updateTag = ({ id, tag }) => async (dispatch, getState) => {
	const res = await api.updateTag({id, tag});
	if (res.error) return res;

	const { tags: currentTags } = getState().user;

	const newTags = currentTags.map(t => t.id === id ? ({
		...t,
		value: tag,
	}) : t);

	dispatch(setTags(newTags));

	return true;
};

export const deleteTags = ({ ids }) => async (dispatch, getState) => {
	const res = await api.deleteTags(ids);
	if (res.error) return res;

	const { tags: currentTags } = getState().user;

	const newTags = currentTags.filter(t => !ids.includes(t.id));

	dispatch(setTags(newTags));

	return true;
};

export const hideGmailConnect = displayGmailConnect => async dispatch => {
	await api.updateUserPreferences({ hideGmailConnect: displayGmailConnect });
	dispatch(hideGmailConnectAction(displayGmailConnect))
}

export const setSendingMethod = method => async (dispatch, getState) => {
	const { user: { sendingMethod } } = getState();

	if (method === sendingMethod) return null;

	await api.updateUserPreferences({ sendingMethod: method });
	dispatch(sendingMethodAction(method));
}

export const setUserPreferences = value => async (dispatch, getState) => {
	const { user: { unacknowledgedFeatures }} = getState();

	const data = unacknowledgedFeatures?.filter(item => item !== value);
	await api.updateUserPreferences({ unacknowledgedFeatures: data });
	dispatch(sendingUserPreferences(value));
}

export const getIntegrations = () => async dispatch => {
  const loadingId = 'getIntegrations';
  dispatch(setControlledLoader({ id: loadingId, show: true }));
  const integrations = await api.getIntegrations();
  if (integrations && !integrations.error) {
    dispatch(saveIntegrations(integrations));
  }
  dispatch(setControlledLoader({ id: loadingId, show: false }));
};

export const getCategoryEmails = () => async dispatch => {
	const category = await api.getCategoryEmails();
	if (!category || category.error) return null;
	dispatch(userUpdate({
		key: 'contactCategory',
		data: category,
	}));
}

export const hasActiveFilter = data => dispatch => {
	dispatch(activeFilter(data));
};
