import React, {
  ComponentProps,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import PhoneInput, {
  isPossiblePhoneNumber,
  formatPhoneNumberIntl,
} from 'react-phone-number-input';
import type { Country, Value } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import getIpGeolocationData from 'utils/getIpGeolocationData';

import ContainerComponent from './ContainerComponent';
import CountrySelectComponent from './CountrySelectComponent';
import InputComponent from './InputComponent';

type Props = {
  value?: Value;
  defaultCountryCode?: Country;
  // defaultPhoneNumber?: string;
  onChange: (props: {
    value: Value;
    countryCode: string;
    isPossible: boolean;
  }) => void;
  error?: string;
};

const InputPhoneWithCountrySelector: React.FC<Props> = ({
  value,
  defaultCountryCode: _defaultCountryCode = undefined,
  // defaultPhoneNumber = '',
  onChange,
  error = '',
}) => {
  const intl = useIntl();

  const [countryCode, setCountryCode] = useState<string | undefined>(
    _defaultCountryCode,
  );
  const [defaultCountryCode, setDefaultCountryCode] =
    useState(_defaultCountryCode);

  const inputRef = useRef<ComponentProps<typeof PhoneInput>['ref']>(null);

  const handleChange = useCallback(
    (_value) => {
      onChange({
        value: _value,
        countryCode: countryCode || '',
        isPossible: isPossiblePhoneNumber(_value || ''),
      });
    },
    [countryCode, onChange],
  );

  const handleChangeCountryCode = useCallback((selectedCountryCode) => {
    setCountryCode(selectedCountryCode);
    setTimeout(() => inputRef.current?.focus(), 200);
  }, []);

  useEffect(() => {
    if (!defaultCountryCode) {
      (async () => {
        const countryCode = await getIpGeolocationData();
        if (countryCode) setDefaultCountryCode(countryCode as Country);
      })();
    }
  }, [defaultCountryCode]);

  return (
    <PhoneInput
      ref={inputRef}
      value={value}
      onChange={handleChange}
      onCountryChange={handleChangeCountryCode}
      placeholder={intl.formatMessage({
        id: 'auth.doubleAuthentication.modal.setup.placeholder',
        defaultMessage: 'xx xx xx xx',
      })}
      autoComplete="off"
      addInternationalOption={false}
      international
      defaultCountry={defaultCountryCode}
      containerComponent={ContainerComponent}
      countrySelectComponent={CountrySelectComponent}
      inputComponent={InputComponent}
      focusInputOnCountrySelection
      error={value && (error || !isPossiblePhoneNumber(value))}
      errorMessage={error}
    />
  );
};

export { formatPhoneNumberIntl, isPossiblePhoneNumber };

export default InputPhoneWithCountrySelector;
