export const DOUBLE_AUTHENTICATION_MODAL_ID =
  'setup_double_authentication_modal';

export const CODE_VERIFICATION_MODAL = 'CodeVerificationModal';

export enum DoubleAuthenticationStep {
  METHOD = 'METHOD',
  ENTER_PHONE = 'ENTER_PHONE',
  DOWNLOAD_APP = 'DOWNLOAD_APP',
  SCAN_QR_CODE = 'SCAN_QR_CODE',
  CODE_VERIFICATION_BY_SMS = 'CODE_VERIFICATION_BY_SMS',
  CODE_VERIFICATION_BY_APP = 'CODE_VERIFICATION_BY_APP',
  SUCCESS = 'SUCCESS',
}

export const STEPS_BY_SMS = [
  DoubleAuthenticationStep.METHOD,
  DoubleAuthenticationStep.ENTER_PHONE,
  DoubleAuthenticationStep.CODE_VERIFICATION_BY_SMS,
  DoubleAuthenticationStep.SUCCESS,
];

export const STEPS_BY_APP = [
  DoubleAuthenticationStep.METHOD,
  DoubleAuthenticationStep.DOWNLOAD_APP,
  DoubleAuthenticationStep.SCAN_QR_CODE,
  DoubleAuthenticationStep.CODE_VERIFICATION_BY_APP,
  DoubleAuthenticationStep.SUCCESS,
];
