import { useMemo } from 'react';
import { INSTAGRAM, NETWORKS } from 'constants/networks';
import { SavedSearch, SliceState } from 'slices/search/searchEngine.slice';
import { useSelector } from 'utils/redux';

import {
  INSTAGRAM_TOGGLES,
  PROFILE_TOGGLES,
} from '../modules/tabsByNetwork/config';

type FiltersForNetwork = Record<
  'profile' | (typeof NETWORKS)[number],
  | SliceState['profile_filters']
  | SliceState['instagram_filters']
  | SliceState['tiktok_filters']
  | SliceState['facebook_filters']
  | SliceState['youtube_filters']
  | SliceState['twitter_filters']
  | SliceState['snapchat_filters']
>;

type UseFiltersByNetworkHooks = (searchFilters?: SavedSearch) => {
  network_filters_operator: SliceState['network_filters_operator'];
  filtersForNetwork: FiltersForNetwork;
  networkWithFilters: (typeof NETWORKS)[number][];
  hasFiltersApplied: boolean;
  AVAILABLE_NETWORKS: (typeof NETWORKS)[number][];
};

export const getFiltersByNetwork = (
  searchFilters: SavedSearch | SliceState,
) => {
  const {
    basic_filters,
    profile_type,
    exclude_favorites,
    excluded_project_ids,
    excluded_list_ids,
    excluded_tag_ids,
    profile_genders,
    profile_filters,
    instagram_filters,
    tiktok_filters,
    youtube_filters,
    facebook_filters,
    twitter_filters,
    snapchat_filters,
  } = searchFilters;

  // Compute the number of filters for each network / KOL filter
  // Some toggles are meant for instagram, other for profile;
  // we need to separate them
  const { instagramToggles, profileToggles } = basic_filters.reduce(
    (separated, toggle) => {
      if (INSTAGRAM_TOGGLES.map((toggle) => toggle.name).includes(toggle)) {
        return {
          ...separated,
          instagramToggles: [...separated.instagramToggles, toggle],
        };
      }
      if (PROFILE_TOGGLES.map((toggle) => toggle.name).includes(toggle)) {
        return {
          ...separated,
          profileToggles: [...separated.profileToggles, toggle],
        };
      }
      return separated;
    },
    { instagramToggles: [] as string[], profileToggles: [] as string[] },
  );

  return {
    instagram: Object.assign(
      {},
      instagram_filters,
      instagramToggles.length
        ? instagramToggles.reduce(
            (obj, toggle) => ({ ...obj, [toggle]: true }),
            {},
          )
        : null,
    ),
    tiktok: tiktok_filters || {},
    facebook: facebook_filters || {},
    youtube: youtube_filters || {},
    twitter: twitter_filters || {},
    snapchat: snapchat_filters || {},
    // Some profile filters are outside `profile_filters`
    profile: Object.assign(
      {},
      profile_filters,
      profileToggles?.length
        ? profileToggles.reduce(
            (obj, toggle) => ({ ...obj, [toggle]: true }),
            {},
          )
        : null,
      profile_type !== 'all' ? { profile_type } : null,
      exclude_favorites
        ? {
            exclude_favorites,
          }
        : null,
      excluded_project_ids?.length
        ? {
            excluded_project_ids,
          }
        : null,
      excluded_list_ids?.length
        ? {
            excluded_list_ids,
          }
        : null,
      excluded_tag_ids?.length
        ? {
            excluded_tag_ids,
          }
        : null,
      profile_genders?.length
        ? {
            profile_genders,
          }
        : null,
    ),
  };
};

// Default to filters in redux
const useFiltersByNetwork: UseFiltersByNetworkHooks = (
  searchFilters = undefined,
) => {
  const reduxFilters = useSelector(
    ({ search: { searchEngine } }) => searchEngine,
  );

  const { network_filters_operator, required_networks } =
    searchFilters || reduxFilters;

  const AVAILABLE_NETWORKS = useMemo(
    () => (reduxFilters.search_scope === 'visual' ? [INSTAGRAM] : NETWORKS) as (typeof NETWORKS)[number][],
    [reduxFilters.search_scope]
  );

  // Compute the number of filters for each network / KOL filter
  const filtersForNetwork: FiltersForNetwork = useMemo(
    () => getFiltersByNetwork(searchFilters || reduxFilters),
    [reduxFilters, searchFilters],
  );

  const networkWithFilters = useMemo(
    () =>
      Object.keys(filtersForNetwork).filter(
        (network) =>
          network !== 'profile' &&
          (required_networks?.includes(network as (typeof NETWORKS)[number]) ||
            Object.keys(filtersForNetwork[network]).length),
      ) as (typeof NETWORKS)[number][],
    [filtersForNetwork, required_networks],
  );

  const hasFiltersApplied = useMemo(() => {
    const { terms, required_networks } = searchFilters || reduxFilters;
    if (terms?.length > 0 || required_networks.length > 0) return true;
    return Object.keys(filtersForNetwork).some(
      (network) => Object.keys(filtersForNetwork[network]).length > 0,
    );
  }, [filtersForNetwork, reduxFilters, searchFilters]);

  return {
    network_filters_operator,
    filtersForNetwork,
    networkWithFilters,
    hasFiltersApplied,
    AVAILABLE_NETWORKS,
  };
};

export default useFiltersByNetwork;
