import _get from 'lodash.get';
import _orderBy from 'lodash.orderby';
import _groupBy from 'lodash.groupby';
import _keyBy from 'lodash.keyby';

import { networks } from 'config/filters';
import { BACKEND_DATE_FORMAT, FRONTEND_DATE_FORMAT } from 'config/app';
import { REACHABILITY_QUALITY } from 'constants/states';
import { removeUndefinedFields } from 'utils/objectUtils';
import { getPeriodByPreset } from 'utils/dateRangeUtil';
import { getSnaUrlNetworkType, formatSnaUrl } from 'validators/sna';

import { mapInteractionDetails, entitySocialPostsSort } from './posts';


const REACHABILITY_RANGES = {
  range_0_500: '0-500',
  range_500_1000: '500-1000',
  range_1000_1500: '1000-1500',
  range_1500_plus: '1500-',
};

const mapSocialDatas = {
  fromApi: datas => {
    const audienceReachability = datas.audience_reachability || [];
    const gendersPerAge = datas.genders_per_age || [];
    const audienceLanguages = datas.audience_languages || [];
    const audienceInterests = datas.audience_interests || [];
    const topCountries = datas.top_countries || [];

    const totalWomen = gendersPerAge.reduce((p, c) => p+(c.F || 0), 0);
    const totalMen = gendersPerAge.reduce((p, c) => p+(c.M || 0), 0);

    return {
      genders: {
        women: Math.round(totalWomen*1000)/10,
        men: Math.round(totalMen*1000)/10
      },
      ages: gendersPerAge.map(({ F, M, slice }) => {
        const women = F || 0;
        const men = M || 0;
        const total = women + men;
        return {
          label: slice,
          womenPercent: Math.round(women*1000)/10,
          menPercent: Math.round(men*1000)/10,
          totalPercent: total > 0 ? Math.round(total * 1000)/10 : 0,
        }
      }),
      locations: _orderBy(topCountries, ['value'], ['desc']).map(({ code, value, cities }) => ({
        code,
        value,
        cities: _orderBy(cities, ['value'], ['desc']).map(({ name, value:cityValue }) => ({
          name,
          value: cityValue
        }))
      })),
      interests: _orderBy(audienceInterests, ['weight'], ['desc']).map(({ name, weight }) => ({
        label: name,
        value: weight,
        percent: Math.round(weight*1000)/10,
      })),
      languages: _orderBy(audienceLanguages, ['value'], ['desc']).map(({ lang, value }) => ({
        label: lang,
        value,
        percent: Math.round(value*1000)/10,
      })).slice(0, 5),
      reachability: audienceReachability.length ? {
        values: audienceReachability.map(({ code, weight }) => ({
          label: code,
          value: weight,
          percent: Math.round(weight*1000)/10,
        })),
        quality: getReachabilityQualityScore(audienceReachability)
      }: null,
    }
  },
}

const getReachabilityQualityScore = reachability => {
  const r = reachability.reduce( (p, c) => ({
    ...p,
    [c.code]: c.weight,
  }), {});

  if ( (r[REACHABILITY_RANGES.range_0_500] >= 0.4 || r[REACHABILITY_RANGES.range_500_1000] >= 0.6) &&
    r[REACHABILITY_RANGES.range_1500_plus] < 0.1
  ) return REACHABILITY_QUALITY.GOOD;

  if (r[REACHABILITY_RANGES.range_1000_1500] >= 0.5 || r[REACHABILITY_RANGES.range_1500_plus] >= 0.5)
    return REACHABILITY_QUALITY.BAD;

  return REACHABILITY_QUALITY.AVERAGE;
}

const formatCoreSpokenLanguage = {
  fra: 'french',
  deu: 'german',
  eng: 'english',
  ita: 'italian',
  spa: 'spanish',
}

export const loadProfileCore = {
  fromApi: datas => {
    const snas = datas?.snas || [];
    const primarySna = snas.find(sna => sna.id === datas.primary_sna_id) || {};

    const getQualityScore = () => {
      if (datas.community_credibility) return datas.community_credibility;

      const qScore = _get(primarySna, 'followers_insights.quality_score');
      if (!qScore) return null;

      return qScore * 100;
    }

    const getFullName = () => {
      const { first_name, last_name, full_name } = datas;
      if (full_name) return full_name;

      if (first_name) {
        if (last_name) return `${first_name} ${last_name}`;
        return first_name;
      }

      if (last_name) return last_name;

      return '';
    }

    const core = Object.assign({
      pseudo: datas?.pseudo,
      enlisted: datas?.enlisted,
      fullName: getFullName(),
      firstName: _get(datas, 'first_name', ''),
      lastName: _get(datas, 'last_name', ''),
      gender: datas?.gender,
      spokenLanguage: formatCoreSpokenLanguage[datas.lang],
      isFavorite: datas?.is_favorite || false,
      themes: datas?.influence_themes || [],
      activities: datas?.activities || [],
      picture: String(datas?.picture).replace('http://', 'https://'),
      picture_fallback: String(datas?.profile_fallback_url).replace('http://', 'https://'),
      isContactable: datas?.contactable || false,
      isInactive: datas?.is_inactive || false,
      contactableEmail: datas?.contactable_email || '',
      hasCommunityAccess: datas?.has_community_access || false,
      hasNote: datas?.has_note || false,
      qualityScore: getQualityScore(),
      totalCommunityCount: snas.reduce((p, c) => p + c.community_count || 0 , 0),
      type: datas?.type ? datas.type.toLowerCase() : null,
      owners: datas?.contact_names || [],
      primarySnaId: datas?.primary_sna_id || null,
      isStatsSharingActive: datas?.is_stats_sharing_active || false,
      arpp_certified: datas?.arpp_certified || false,
      werberat_certified: datas?.werberat_certified || false,
      influencerregels_certified: datas?.influencerregels_certified || false,
    })

    // Ages & genders
    const gendersPerAge = _get(primarySna, 'followers_insights.genders_per_age', []);
    const topAges = _orderBy(
      gendersPerAge.map(slice => ({
        ...slice,
        total: (slice.M || 0) + (slice.F || 0),
      })),
      ['total'], ['desc']
    );

    // Top interest
    const topInterests = _orderBy(
      _get(primarySna, 'followers_insights.audience_interests', []),
      ['weight'],
      ['desc']
    );

    const totalWomenPercent = gendersPerAge.reduce((p, c) => p+(c.F || 0), 0);
    const topGenders = _orderBy([
      { slice: 'women', total: totalWomenPercent },
      { slice: 'men', total: 1-totalWomenPercent },
    ], ['total'], ['desc']);

    // Reachability
    const reachability = _get(primarySna, 'followers_insights.audience_reachability', []);

    // Countries
    const countries =  _orderBy(
      _get(primarySna, 'followers_insights.top_countries', []).map(country => ({
        ...country,
        cities: _orderBy(country.cities, ['value'], ['desc'])
      })),
      ['value'],
      ['desc']
    );

    const top = {
      countries,
      ages: topAges,
      interests: topInterests,
      genders: topGenders,
      reachability,
    }

    const groupedSnas = _groupBy(snas, 'provider');

    const sortedSnas = {
      byCommunityCount: _orderBy(datas.snas, ['community_count'], ['desc']).map(j => j.id),
      bycommitmentPerPost: _orderBy(datas.snas, ['avg_engagement_per_post_absolute'], ['desc']).map(j => j.id),
      byNetworks: networks.reduce((p, key) => {
        const snasByNetwork = groupedSnas[key] || null;
        return Object.assign(p, {
          [key]: snasByNetwork ? {
            ids: snasByNetwork.map(j => j.id),
            communityCount: _orderBy(snasByNetwork, ['community_count'], ['desc']).map(j => j.id),
            commitmentPerPost: _orderBy(snasByNetwork, ['avg_engagement_per_post_absolute'], ['desc']).map(j => j.id),
            viewsPerPost: _orderBy(snasByNetwork, ['avg_views_count'], ['desc']).map(j => j.id),
            commitmentPerMediaPercent: _orderBy(snasByNetwork, ['avg_engagement_per_post_percent'], ['desc']).map(j => j.id),
          } : {
            ids: [],
            communityCount: [],
            viewsPerPost: [],
            commitmentPerPost: [],
            commitmentPerMediaPercent: [],
          }
        })
      }, {})
    }

    return Object.assign({
      username: datas?.username,
      core,
      availableNetworks: Object.keys(groupedSnas),
      snas: _keyBy(_orderBy(snas,
        ['community_count'],
        ['desc']).map(mapSna.fromApi),
        'id'),
      sortedSnas,
      affilae: {
        profileId: datas.affilae_id
      }
    },
    datas.tags ? { tags: datas?.tags?.map(id => String(id)) } : null,
    primarySna && primarySna.followers_insights ? { top } : null,
    datas.stats_sharing_request ? { statsSharing: { status: datas.stats_sharing_request }} : null,
  )}
}

export const loadProfileAudience = {
  fromApi: datas => {
    const primarySna = (datas.snas || []).find(sna => sna.id === datas.primary_sna_id) || {};

    const snas = (datas.snas || []).reduce((prev, curr) => ({
      ...prev,
      [curr.id]: {
        followers: mapSocialDatas.fromApi(curr.followers_insights || {}),
        likers: mapSocialDatas.fromApi(curr.likers_insights || {}),
        commenters: mapSocialDatas.fromApi(curr.commenters_insights || {}),
        viewers: mapSocialDatas.fromApi(curr.views_insights || {}),
      }
    }), {});
    return {
      snas,
      hasFollowersInsights: primarySna && Boolean(primarySna.followers_insights),
    }
  }
}

export const loadProfileAbout = {
  toApi: ({ search }) => Object.assign({},
    search ? { bios_search: search } : null,
  ),
  fromApi: datas => ({
    location: {
      country: _get(datas, 'address.country_code', null),
      city: _get(datas, 'address.city', null),
    },
    socialDataLocation: {
      country: _get(datas, 'secondary_address.country_code', null),
      city: _get(datas, 'secondary_address.city', null),
    },
    homepage: _get(datas, 'homepage_url', ''),
    languages: (datas.langs || []).filter(lang => lang !== '__unknown__' && lang !== 'other').slice(0,5),
    snas: (datas.snas || []).map(sna => ({
      id: sna.id,
      username : sna.username || '',
      biography: sna.biography,
    })),
  })
}

export const loadProfileStats = {
  fromApi: datas => (datas || []).map(mapSnaStats.fromApi)
}

export const loadProfileSnasEmv = {
  fromApi: snas => (snas || []).map(sna => ({
    id: sna.id,
    emv: sna.emv
  })),
}

export const mapPosts = posts => (posts || []).map(post => ({
  id: String(post.id),
  profileId: post.profile_id,
  snaId : post.sna_id,
  network: post.social_network,
  username: post.username || '',
  publishedAt: post.published_at,
  contentUrl: post.content_url,
  picture: post.picture_url,
  video: post.video_url,
  content: post.content,
  stickerLinkUrl: post?.sticker_link_url,
  avgCommitments: post.avg_engagement,
  interactionDetails: mapInteractionDetails.fromApi(post.engagement_details),
  avatarUrl: post.avatar_url,
  type: post.type || 'picture',
  isStory: post.story,
	isIgtv: post.is_igtv,
  isVideoExpired: !!post.is_video_expired,
	isReel: post.is_reel,
  isSnapchatStory: !!post.is_saved_story,
  isSnapchatSpotlight: !!post.is_spotlight,
}));

export const loadProfilePosts = {
  toApi: ({ selectedSnas, search, sortBy, date, page, types }) => ({
    account_ids: selectedSnas,
    sort_by: entitySocialPostsSort[sortBy],
    page,
    content: search,
    publication_type: types,
    ...getPeriodByPreset(date.slice, BACKEND_DATE_FORMAT, FRONTEND_DATE_FORMAT)
  }),
  fromApi: ({ total, posts, stats, top_hashtags }) => ({
    total: total || 0,
    posts: mapPosts(posts),
    statsSnas: (stats || []).map(stat => ({
      label: stat.social_network,
      snaId: stat.sna_id.toString(),
      posts: {
        total: stat.posts.total,
        matched: {
          absolutePosts: stat.posts.matched_posts || null,
          absoluteStories: stat.posts.matched_stories || null,
          absoluteReels: stat.posts.matched_reels || null,
          percent: stat.posts.matched_percent,
        },
      },
      avgMatchedCommintments: stat.avg_matched_engagement,
      avgCommitmentsPerPost: stat.avg_engagement_per_post,
    })),
    snas: (stats || []).map(stat => ({
      id: String(stat.sna_id),
      posts: {
        total: stat.posts.total,
        matched: {
          commitments: stat.avg_matched_engagement,
          absolute: stat.posts.matched,
          percent: stat.posts.matched_percent,
        },
      }
    })),
    topHashtags: Object.entries(top_hashtags || {}).map(([label, value]) => ({
      label,
      value,
    })),
  }),
};

export const loadProfileNote = {
  toApi: ({id}) => ({
    public_profile_id : id,
  }),
  fromApi : ({content, created_at, updated_by}) => ({
    content: content || '',
    updatedBy: updated_by || null,
    createdAt: created_at || null
  })
};

export const loadProfileCampaignsIds = {
  fromAPi: ids => (ids && ids.length) ? ids.map(id => Number(id)) : [],
};

export const loadProfileCampaignsHistory = {
  fromApi: campaigns => !campaigns
    ? []
    : campaigns?.map(c => ({
      id: String(c?.id),
      name: c?.name,
      createdAt: c?.created_at,
      kolStatus: c?.status || null,
      createdBy: c?.created_by,
      isMine: c?.is_mine || false,
      discount_code: c?.discount_code || null,
      state: c?.state,
      invested_budget: c?.invested_budget,
      invested_budget_currency: c?.invested_budget_currency,
    })),
}

const getLocation = string => {
  return (!string || JSON.stringify(string) === 'null' || string === 'NONE') ? '' : string;
} // if gmap is validate -> move it to utils

export const getProfileDetails = {
  fromApi: data => {
    const { address, address2, city, country, zip_code } = data;
    return {
      affilaeId: _get(data, 'affilae_id', ''),
      zipCode: _get(data, 'zip_code', '') ,
      phoneNumber: _get(data, 'phone_number', ''),
      agentName: _get(data, 'agent_name', ''),
      agentEmail: _get(data, 'agent_email', ''),
      agentPhoneNumber: _get(data, 'agent_phone_number', ''),
      pseudo: _get(data, 'pseudo', ''),
      firstName: _get(data, 'first_name', ''),
      lastName: _get(data, 'last_name', ''),
      lists: _get(data, 'list_ids', []),
      fields: _get(data, 'custom_fields', {}),
      projects_invested_budget: _get(data, 'projects_invested_budget', 0),
      projects_invested_budget_currency: _get(data, 'projects_invested_budget_currency', null),
      address: (!address2 && !city && !country)
        ? _get(data, 'address', '')
        : `${getLocation(address)} ${getLocation(address2)} ${getLocation(zip_code)} ${getLocation(city)} ${getLocation(country)}`,
    }
  },
  toApi: ({
    affilaeId: affilae_id,
    zipCode: zip_code,
    phoneNumber: phone_number,
    agentName: agent_name,
    agentEmail: agent_email,
    agentPhoneNumber: agent_phone_number,
    investedBudget: invested_budget,
    firstName: first_name,
    lastName: last_name,
    address,
    email,
    pseudo,
    ...rest
  }) => ({
    detail: removeUndefinedFields({
      agent_name,
      agent_email,
      agent_phone_number,
      invested_budget,
      pseudo,
      first_name,
      last_name,
      phone_number,
      email,
      address,
      affilae_id,
      ...rest,
    }),
  }),
};

export const loadProfileLabels = {
  fromApi: tags => (tags || []).map(({ id }) => id)
}

export const loadProfileTopHashtags = {
  fromApi: hashtags => hashtags
}

/* "Full" profile mapper */
export const mapProfiles ={
  fromApi: profiles => (profiles || []).map(mapProfile.fromApi),
};

export const mapProfile = {
  fromApi: profile => {
    const mainDetails = loadProfileCore.fromApi(profile);

    return {
      id: Number(profile.id),
      ...mainDetails
    }
  }
}

export const mapSnaStats = {
  // Note: check key with "in" operator is needed here to not overwrite the object with other endpoints to update stats.
  fromApi : sna => sna
    ? Object.assign(
      { id: (sna.id || '').toString()},
      'community_count' in sna && { communityCount : sna.community_count },
      'community_progression_rate' in sna && { communityProgressionRate : sna.community_progression_rate },
      'posts_per_week' in sna && { postsPerWeek : sna.posts_per_week },
      'stories_per_week' in sna && { storiesPerWeek : sna.stories_per_week },
      'reels_per_week' in sna && { reelsPerWeek : sna.reels_per_week },
      'avg_likes_count' in sna && { likesPerPost : sna.avg_likes_count },
      'avg_likes_reel' in sna && { likesPerReel : sna.avg_likes_reel },
      'avg_comments_count' in sna && { commentsPerPost : sna.avg_comments_count },
      'avg_comments_reel' in sna && { commentsPerReel : sna.avg_comments_reel},
      'avg_dislikes_count' in sna && { dislikesPerPost : sna.avg_dislikes_count },
      'avg_shares_count' in sna && { sharesPerPost : sna.avg_shares_count },
      'avg_views_count' in sna && { viewsPerPost : sna.avg_views_count },

      'avg_engagement_per_post' in sna && { commitmentPerMedia : sna.avg_engagement_per_post},
      'avg_engagement_rate_per_post' in sna && { commitmentPerMediaPercent : sna.avg_engagement_rate_per_post},
      'avg_engagement_stats_per_post' in sna && { commitmentPerPost : sna.avg_engagement_stats_per_post},
      'avg_engagement_rate_stats_per_post' in sna && { commitmentPerPostPercent : sna.avg_engagement_rate_stats_per_post},
      'avg_engagement_per_reel' in sna && { commitmentPerReel : sna.avg_engagement_per_reel},
      'avg_engagement_rate_per_reel' in sna && { commitmentPerReelPercent : sna.avg_engagement_rate_per_reel},
      'engagement_rate_progression' in sna && { commitmentPerMediaProgressionRate : sna.engagement_rate_progression},

      'impressions_per_post' in sna && { impressionsPerPost : sna.impressions_per_post},
      'impressions_per_story' in sna && { impressionsPerStory : sna.impressions_per_story},
      'impressions_per_reel' in sna && { impressionsPerReel : sna.impressions_per_reel},

      'reach_per_post' in sna && { reachPerPost: sna.reach_per_post},
      'reach_per_story' in sna && { reachPerStory: sna.reach_per_story},
      'reach_per_reel' in sna && { reachPerReel: sna.reach_per_reel},

      // Snapchat:
      'avg_engagements_per_saved_stories' in sna && { commitmentPerSavedStory: sna.avg_engagements_per_saved_stories },
      'engagement_rate_per_saved_stories' in sna && { commitmentPerSavedStoryPercent: sna.engagement_rate_per_saved_stories },
      'avg_engagements_per_spotlights' in sna && { commitmentPerSpotlight: sna.avg_engagements_per_spotlights },
      'engagement_rate_per_spotlights' in sna && { commitmentPerSpotlightPercent: sna.engagement_rate_per_spotlights },
      'stories_engagements_3m' in sna && { commitmentPerStory: sna.stories_engagements_3m },
      'stories_engagement_rate_3m' in sna && { commitmentPerStoryPercent: sna.stories_engagement_rate_3m },
      'reach_saved_stories' in sna && { reachPerSavedStory: sna.reach_saved_stories},
      'reach_per_spotlights' in sna && { reachPerSpotlight: sna.reach_per_spotlights},
      'stories_engagement_avg_reach_3m' in sna && { reachPerStory: sna.stories_engagement_avg_reach_3m},
      'saved_stories_per_week' in sna && { savedStoriesPerWeek : sna.saved_stories_per_week },
      'spotlights_per_week' in sna && { spotlightPerWeek : sna.spotlights_per_week },
      'stories_engagement_avg_per_week_3m' in sna && { storiesPerWeek : sna.stories_engagement_avg_per_week_3m },
      'avg_shares_saved_stories' in sna && { sharesPerSavedStory : sna.avg_shares_saved_stories },
      'avg_shares_spotlights' in sna && { sharesPerSpotlight : sna.avg_shares_spotlights },
      'stories_engagement_shares_3m' in sna && { sharesPerStory : sna.stories_engagement_shares_3m },

      'emv' in sna && { emv: sna.emv },
      'emv_per_post' in sna && { emv_per_post: sna.emv_per_post },
      'emv_per_reel' in sna && { emv_per_reel: sna.emv_per_reel },
      'emv_per_story' in sna && { emv_per_story: sna.emv_per_story },

      // collaboration score
      'sponsored_post_rate_3m' in sna && { 'sponsored_post_rate_3m': sna.sponsored_post_rate_3m },
      'compliance_score' in sna && { 'compliance_score': sna.compliance_score },
      'avg_brand_mentions_per_post_3m' in sna && { 'avg_brand_mentions_per_post_3m': sna.avg_brand_mentions_per_post_3m },
      'compliance_score_country' in sna && { 'compliance_score_country': sna.compliance_score_country },
      'compliance_score_enabled' in sna && { 'compliance_score_enabled': sna.compliance_score_enabled },
      'compliance_score_disabled_reason' in sna && { 'compliance_score_disabled_reason': sna.compliance_score_disabled_reason },

      {contentEstimatedPrice: {
        ...((sna?.min_emv_per_post && sna?.max_emv_per_post) && {
          post: {
            min: sna.min_emv_per_post || null,
            max: sna.max_emv_per_post || null
          }
        }),
        ...((sna?.min_emv_per_story && sna?.max_emv_per_story) && {
          story: {
            min: sna.min_emv_per_story || null,
            max: sna.max_emv_per_story || null
          }
        }),
        ...((sna?.min_emv_per_reel && sna?.max_emv_per_reel) && {
          reel: {
            min: sna.min_emv_per_reel || null,
            max: sna.max_emv_per_reel || null
          }
        })
      },
    })
    : {}
}


export const mapSna = {
  fromApi: sna => Object.assign({
      id: String(sna.id),
      uid: String(sna.uid),
      username: sna.username || '',
      pseudo: sna.pseudo,
      isBlocked : sna.is_blocked || false,
      isPrivate: sna.is_private || false,
      isInactive: sna.is_inactive || false,
      isDeleted: sna.is_deleted || false,
      isRestricted: sna.is_restricted || false,
      isVerified: sna.is_verified || false,
      network: sna.provider,
      stats: mapSnaStats.fromApi(sna),
      audience_credibility_score: sna.audience_credibility_score,
    },
    sna.url ? { url: sna.url } : null,
  )
}

export const addMultipleTags = {
  toApi: ({ids, tagsIds}) => ({
    tag_ids: tagsIds,
    ids
  })
}

export const updateProfileTags = {
  toApi: ({tags}) => ({ tag_ids: tags})
}

export const updateProfilesTags = {
  toApi: ({ids,tags}) => ({ tag_ids: tags, profile_ids: ids})
}

export const getTags = {
  fromApi: tags => tags?.map(({ id }) => (id || '').toString())
}

export const getTopMentions = {
  fromApi: mentions => (mentions || []).map(mention => ({
    id: mention.id || null,
    username: mention.username || '',
    network: mention.provider,
    url: mention.url,
    profileId: mention.profile_id || null,
    picture: mention.picture_url,
    count: mention.mentions_count || 0,
  })),
  toApi: ({ date, network }) => ({
    period: date.slice === 'lastMonth' ? '1m' : date.slice === 'last6Months' ? '6m' : 'all',
    network,
  }),
}

const reportsAPI = {
  inappropriateContent: 'inappropriate_content',
  hate: 'hate',
  scam: 'scam',
  porno: 'porno',
  violent: 'violent',
  harassment: 'harassment',

  irrelevantProfile: 'irrelevant_profile',
  deceased: 'deceased',
  inactive: 'inactive',
  incorrectNetwork: 'incorrect_network',

  wrongCategory: 'wrong_category',
  isAPerson: 'is_a_person',
  isABrand: 'is_a_brand',
  isAMedia: 'is_a_media',
  isAnAnimal: 'is_an_animal',
  isAPlace: 'is_a_place',

  duplicate: 'duplicate',
  other: 'other',
}

export const reportProfile = {
  toApi: ({ key, value }) => ({
    report: Object.assign(
      {name: key !== 'duplicate' && key !== 'other' ? `${reportsAPI[key]}/${reportsAPI[value]}` : reportsAPI[key] },
      (key === 'duplicate' || key === 'other') && {description: value}
    )
  })
}

export const reportThemes = {
  toApi: ({ wrongThemes, proposedThemes }) => ({
    report: {
      name: "wrong_theme",
      description: wrongThemes.length > 0
        ? `Mauvais thème(s): ${wrongThemes.join(', ')} - Thèmes proposés: ${proposedThemes?.trim()}`
        : `Thèmes proposés: ${proposedThemes?.trim()}`
    }
  })
}

export const suggestInfluencer = {
  toApi: ({ lines, lists, campaigns, labels }) => ({
    accounts: lines?.map((line) => {
      const ignoreRegex =
        line.includes('youtube.com') || line.includes('facebook.com');
      return {
        url: formatSnaUrl(line, ignoreRegex),
        type: getSnaUrlNetworkType(line),
      };
    }),
    list_ids: lists,
    project_ids: campaigns,
    tag_ids: labels,
  }),
};

export const getLookalike = {
  fromApi: data => ({
    total: _get(data, 'total', 0),
    rows: _get(data, 'rows', []).map(row => ({
      id: _get(row, 'id', null),
      fullName: _get(row, 'full_name', ''),
      pseudo: _get(row, 'pseudo', ''),
      gender: _get(row, 'gender', ''),
      picture: _get(row, 'picture_url', ''),
      primarySnaUrl:  _get(row, 'primary_sna_url', null),
      activities: _get(row, 'activities', []),
      communityCredibility: _get(row, 'community_credibility', 0),
      snas: _get(row, 'snas', []).map(sna => ({
        id: _get(sna, 'id', null),
        username: _get(sna, 'username', ''),
        uid: _get(sna, 'uid', ''),
        provider: _get(sna, 'provider', ''),
        communityCount: _get(sna, 'community_count',0)
      })),
    }))
  }),
  toApi: ({ profileIds, similarType, network, page, perPage }) => ({
    profile_ids: profileIds,
    similar_type: similarType,
    network,
    page,
    per_page: perPage,
  }),
};

export const loadLegacyProfiles = {
  fromApi: ({ rows }) => rows
    ?.filter(({ id }) => id)
    ?.map(item => ({
      id: _get(item, 'id', null),
      core: {
        pseudo: _get(item, 'pseudo', ''),
        fullName: _get(item, 'name', ''),
        firstName: _get(item, 'first_name', ''),
        lastName: _get(item, 'last_name', ''),
        picture: _get(item, 'picture_url', ''),
        isFavorite: _get(item, 'is_favorite', false),
        hasNote: _get(item, 'has_note', false),
        isContactable: _get(item, 'contactable', false),
        isInactive: _get(item, 'is_inactive', false),
        contactableEmail: _get(item, 'contactable_email', ''),
        arpp: _get(item, 'arpp_certified', false),
        werberat_certified: _get(item, 'werberat_certified', false),
        influencerregels_certified: _get(item, 'influencerregels_certified', false),
      },
      tags: _get(item, 'tags', []).map(({ id }) => id),
      snas: _get(item, 'snas', []).reduce((acc, sna) => ({
        ...acc,
        [sna.id]: {
          id: String(sna.id),
          username: _get(sna, 'username', ''),
          pseudo: _get(sna, 'pseudo', ''),
          isBlocked: _get(sna, 'is_blocked', false),
          isPrivate: _get(sna, 'is_private', false),
          isInactive: _get(sna, 'is_inactive', false),
          isDeleted: _get(sna, 'is_deleted', false),
          isRestricted: _get(sna, 'is_restricted', false),
          network: _get(sna, 'provider', []),
          stats: mapSnaStats.fromApi(sna),
        }
      }), {}),
      campaigns: {
        loaded: false,
        ids: _get(item, 'project_ids', [])
      }
    })) || [],
}

export const loadCustomFields = {
  fromApi: fields => fields && fields?.reduce((acc, field) => {
    return ({
      ...acc,
      [field?.custom_field_id]: { ...field }
    })
  }, {}),
}
