import { useEffect, useState, useCallback } from 'react';
import getLocalStorageItem from 'utils/getLocalStorageItem';

const useLocalStorage = (key, initialValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    return getLocalStorageItem(key, initialValue);
  });

  const setValue = useCallback(
    (value) => {
      try {
        window.localStorage.setItem(key, JSON.stringify(value));

        // Dispatch a custom event to notify all instance of this hooks about local storage change
        const event = new CustomEvent('onLocalStorageChanged', {
          detail: {
            key,
            newValue: value,
          },
        });
        document.dispatchEvent(event);
      } catch (error) {
        console.error(error);
      }
    },
    [key],
  );

  const handleEventListener = useCallback(
    (e) => {
      if (e.detail?.key === key) {
        setStoredValue(e.detail.newValue);
      }
    },
    [key]
  );

  useEffect(() => {
    document.addEventListener('onLocalStorageChanged', handleEventListener);

    return () => {
      document.removeEventListener('onLocalStorageChanged', handleEventListener);
    };
    // eslint-disable-next-line
  }, []);

  return [storedValue, setValue];
};

export default useLocalStorage;
