export const minimumSupportedBrowserVersion = {
  safari: 16.4,
  firefox: 86,
  edge: 81,
  chrome: 81,
  opera: 68
};

export const minimumSupportedEngineVersion = {
  blink: 120, // Chromium
}

export const browserDownloadLinks = {
  safari: 'https://support.apple.com/en-us/102665',
  firefox: 'https://www.mozilla.org/en-US/firefox/new/',
  edge: 'https://www.microsoft.com/en-us/edge',
  chrome: 'https://www.google.com/chrome/',
  opera: 'https://www.opera.com/download'
};
