import React, { lazy, Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import memoize from 'memoizee';

import { getStore } from 'config/store';
import { useDeepSelector } from 'utils/redux';

const Loader = lazy(() => import('kolkit/Loader'));

export const hasPackageStandard = memoize(() => {
  /* List from backend:
    "1.0 Software Licence Enterprise": Features::Groups::package::STANDARD,
    "1.0.1 Software Licence Enterprise (Moderate)": Features::Groups::package::STANDARD,
    "1.8 Software Licence Paid Trial": Features::Groups::package::STANDARD,
    "1.9 Software Licence Free Trial": Features::Groups::package::STANDARD,
  */
  const licencesForPackageStandard = [
    "1.0 Software Licence Enterprise",
    "1.0.1 Software Licence Enterprise (Moderate)",
    "1.8 Software Licence Paid Trial",
    "1.9 Software Licence Free Trial",
  ]
  const userLicenses = getStore()?.user?.profile?.licences || [];
  return userLicenses.some(license => licencesForPackageStandard.includes(license.name));
});


export const getActiveFeatures = memoize(() => {
  return getStore()?.user?.features?.featuresList || [];
});

export const isFeatureActive = memoize((featureName, options) => {
  if (options?.onlyForKolsquare) return getStore().user.isFromKolsquare;
  if (options?.onlyForCompanyZZZ) return getStore().user.profile.company?.id === 2320; // Id of team "ZZZ test" on prod/staging/lab

  if (typeof featureName === 'string') {
    return getActiveFeatures().includes(featureName);
  }
  const { any, every } = featureName || {};
  if (any && any?.length) {
    return any.some(feature => isFeatureActive(feature, options));
  }
  if (every && every?.length) {
    return every.every(feature => isFeatureActive(feature, options));
  }
  return false;
});

export const decideValueByFeature = memoize(
  ({ featureName, onlyForKolsquare, value, fallback = null }) => {
    if (isFeatureActive(featureName, { onlyForKolsquare })) return value;
    return fallback;
  },
);

export const MountRouteByFeature = ({
  featureName,
  force = false,
  onlyForKolsquare = false,
  onlyForCompanyZZZ = false,
  allowForAdmins = false,
  whiteList = [],
  redirectPath,
}) => {
  const { isProfileLoaded, userId, isAdmin } = useDeepSelector(({ user }) => ({
    isProfileLoaded: !!user.profile,
    userId: user.profile?.id,
    isAdmin: user.profile?.admin,
  }));

  if (!isProfileLoaded)
    return (
      <Suspense>
        <Loader full />
      </Suspense>
    );

  if (
    force ||
    (allowForAdmins && isAdmin) ||
    isFeatureActive(featureName, { onlyForKolsquare, onlyForCompanyZZZ }) ||
    whiteList.includes(Number(userId))
  )
    return <Outlet />;

  return <Navigate to={redirectPath || '/'} />;
};

export const MountComponentByFeature = ({
  featureName,
  force = false,
  onlyForKolsquare = false,
  onlyForCompanyZZZ = false,
  allowForAdmins = false,
  whiteList = [],
  children,
  fallback = null,
}) => {
  return (
    <Suspense>{
      isFeatureActive(featureName, { onlyForKolsquare, onlyForCompanyZZZ, allowForAdmins, force, whiteList })
        ? children
        : fallback
    }</Suspense>
  );
};
