export const TABLE_STATISTICS = {
  COLUMNS: [
    {
      name: 'emv',
      type: 'currency',
      sortable: false
    },
    {
      name: 'kols',
      type: 'volume',
      sortable: false
    },
    {
      name: 'stories',
      type: 'volume',
      sortable: false
    },
    {
      name: 'posts',
      type: 'volume',
      highlight: true,
      sortable: false
    },
    {
      name: 'reels',
      type: 'volume',
      highlight: true,
      sortable: false
    },
    {
      name: 'engagement',
      type: 'volume',
      highlight: true,
      sortable: false,
    },
    {
      name: 'engagementRate',
      type: 'rate',
      highlight: true,
      sortable: false,
      className: 'rate-responsive',
    },
  ],
  GRID_TEMPLATE_COLUMNS: [
    '1.5fr',
    '1fr',
    '1fr',
    '1fr',
    '1fr',
    '1fr',
    '1.25fr',
    '1fr',
  ].reduce((template, span) => template.concat(` minmax(0,${span})`), ``),
};

export const FILTERS_CONTENT_SORT = [
  'most_recent',
  'most_engaging',
  'most_engaging_rate',
  'most_liked',
  'most_commented',
] as const;

export const FILTERS_CONTENT_SORT_DATAID = {
  'most_recent': 'date',
  'most_engaging': 'EV',
  'most_engaging_rate': 'ER',
  'most_liked': 'likes',
  'most_commented': 'comments',
};

export const SOURCES = ['owned', 'campaign', 'organic'];

export const SOURCES_DATA = [
  {
    key: 'owned',
    phrase: 'followersIncrease.sources.owned',
    color: '#74DBFD',
    phraseTooltip: 'followersIncrease.sources.owned.tooltip',
  },
  {
    key: 'campaign',
    phrase: 'followersIncrease.sources.campaign',
    color: '#00A6F4',
    phraseTooltip: 'followersIncrease.sources.campaign.tooltip',
  },
  {
    key: 'organic',
    phrase: 'followersIncrease.sources.organic',
    color: '#0061AC',
    phraseTooltip: 'followersIncrease.sources.organic.tooltip',
  }
];

export const MEDIAS_PER_PAGE = 12;
