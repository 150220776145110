import { createAction } from 'redux-actions';
import moment from 'moment-timezone';

import * as api from 'api/listening';
import * as LISTENING from 'constants/listeningV2';
import history from 'config/history';
import routes from 'config/routes';
import { BACKEND_DATE_FORMAT } from 'config/app';
import { POSTS_PER_PAGE, COLOR_LIST, SELF_COLOR } from 'config/listening';
import { AppThunk } from 'config/store';

import { findDuplicates } from 'utils/arrays';
import generateListeningUrl from 'utils/generateListeningUrl';
import { getIntl } from 'utils/HOCs/IntlGlobalSingleton';
import { isMention, trimMentionForAPI, trimMentionsForAPI, formatMentionForUI } from 'utils/mentions'
import { getTimeRangesByDates } from 'utils/hooks/useTimeRangesByDates';
import toast from 'utils/toast';

import { MODAL_ID, ModalData } from 'components/listeningV2/Modals/EditDashboardModal';

import { hideModal, setControlledLoader, updateModal } from 'actions/ui';

import { DashboardSettingsModel } from 'types/DashboardSettingsModel';

export const closeBanner = createAction(LISTENING.CLOSE_BANNER);
export const updateCurrentListening = createAction(LISTENING.UPDATE_CURRENT_LISTENING);
export const updateListeningSettings = createAction(LISTENING.UPDATE_SETTINGS);
export const resetListeningSettings = createAction(LISTENING.RESET_SETTINGS);
const setListenings = createAction(LISTENING.LISTENINGS_LOADED);
const updateListening = createAction(LISTENING.UPDATE_LISTENING);
const setKpiOverview = createAction(LISTENING.OVERVIEW_LOADED);
const setKpiEvolution = createAction(LISTENING.SET_KPI_EVOLUTION);
const setKolDistribution = createAction(LISTENING.SET_KOL_DISTRIBUTION);
const setTopKols = createAction(LISTENING.SET_TOP_KOLS);
const setKpiShareOfVoices = createAction(LISTENING.SET_SHARE_OF_VOICES);
const setShareOfVoiceByCountry = createAction(LISTENING.SET_SHARE_OF_VOICES_BY_COUNTRY);
const setContents = createAction(LISTENING.SET_CONTENTS);
export const updateKpiFilters = createAction(LISTENING.UPDATE_KPI_FILTERS);


export const getListeningPreference = ({ monitoringId }) => async dispatch => {
  const response = await api.getListeningPreference({ monitoringId });
  const settings = Object.assign({},
    {
      exclude_self_kol: response?.exclude_self_kol || false,
      excluded_mentions: {
        instagram: response?.excluded_mentions?.instagram || [],
        tiktok: response?.excluded_mentions?.tiktok || [],
      }
    },
    response?.start_at && response?.end_at && {
      dateRanges: {
        startDate: response.start_at,
        endDate: response.end_at
      }
    },
  );
  dispatch(updateListeningSettings(settings));

  if(settings.dateRanges) {
    history.push(generateListeningUrl(
      monitoringId,
      {
        startDate: moment(settings.dateRanges.startDate),
        endDate: moment(settings.dateRanges.endDate),
      })
    );
  }
}

export const saveListeningPreferences = (newPreferences) => async (_, getState) => {
  const {
    listeningV2: {
      settings: {
        currentMonitoring,
        excluded_mentions,
        dateRanges,
        exclude_self_kol,
      },
    },
  } = getState();

  await api.saveListeningPreferences({
    monitoringId: currentMonitoring?.monitoring_id,
    excluded_mentions,
    exclude_self_kol,
    start_at: dateRanges.startDate,
    end_at: dateRanges.endDate,
    ...newPreferences
  });
}

export const loadListenings = (monitoringIdFromUrl?: string) => async (dispatch) => {
  dispatch(setControlledLoader({ id: 'getListenings', show: true }));

  // allow admin user to load listening by contact id from url params
  const currentParams = new URLSearchParams(history.location.search);
  const contactIdFromUrl = currentParams?.get('contact_id');
  const response = await api.getListeningsV2(contactIdFromUrl
    ? { contact_id: contactIdFromUrl }
    : {}
  );
  if (response?.error) {
    dispatch(setListenings({ monitorings: [], remaining_monitorings: false}));
  } else {
    dispatch(setListenings(response));

    if (monitoringIdFromUrl) {
      const currentMonitoring = response?.monitorings?.find(
        (item) => Number(item.monitoring_id) === Number(monitoringIdFromUrl),
      );
      if (currentMonitoring) {
        const { instagram: instagramBrands, tiktok: tiktokBrands } =
          currentMonitoring?.brands || { instagram: [], tiktok: [] };

        dispatch(updateCurrentListening(currentMonitoring));

        dispatch(updateListeningSettings({
          network: instagramBrands?.length > 0
            ? 'instagram'
            : tiktokBrands?.length > 0 ? 'tiktok' : null,
          brandsColors: {
            instagram: (instagramBrands || []).reduce((prev, current, index) => ({
              ...prev,
              [current?.mention]:  current?.is_self ? SELF_COLOR : COLOR_LIST[index]
            }), {}),
            tiktok: (tiktokBrands || []).reduce((prev, current, index) => ({
              ...prev,
              [current?.mention]:  current?.is_self ? SELF_COLOR : COLOR_LIST[index]
            }), {}),
          }
        }));
      } else {
        history.push(`/${routes.dashboard}/${routes.listening}`)
      }
    }
  }

  dispatch(setControlledLoader({ id: 'getListenings', show: false }));
  return response?.monitorings;
}

const getRequiredParamsToApi = () => (_, getState) => {
  const {
    settings: {
      currentMonitoring,
      network,
      dateRanges,
      excluded_mentions,
      exclude_self_kol,
    }
  } = getState().listeningV2;
  return {
    id: currentMonitoring?.monitoring_id,
    audience_filters: currentMonitoring.audience_filters,
    network: network || 'instagram',
    excluded_mentions: excluded_mentions[network] || [],
    exclude_self_kol: exclude_self_kol || false,
    start_at: moment(dateRanges?.startDate).format(BACKEND_DATE_FORMAT),
    end_at: moment(dateRanges?.endDate).format(BACKEND_DATE_FORMAT),
  }
}

export const loadKpiOverview = () => async (dispatch, getState) => {
  const { settings: { currentMonitoring } } = getState().listeningV2;
  if (currentMonitoring?.monitoring_id) {
    dispatch(setControlledLoader({ id: 'getOverviewListenings', show: true }));

    const params = dispatch(getRequiredParamsToApi());
    const response = await api.getKpiOverview(params);
    const brands = response?.brands || [];

    const mapShareOfVoices = brands.map((brand) => ({
      mention: brand?.mention,
      stats: {
        contents:
          (brand?.stats?.posts_count || 0) +
          (brand?.stats?.stories_count || 0) +
          (brand?.stats?.reels_count || 0),
        emv:
          (brand?.stats?.posts_emv || 0) +
          (brand?.stats?.stories_emv || 0) +
          (brand?.stats?.reels_emv || 0),
        engagement: brand?.stats.total_engagement || 0,
      }
    }))

    dispatch(setKpiOverview(brands));
    dispatch(setKpiShareOfVoices(mapShareOfVoices));

    dispatch(setControlledLoader({ id: 'getOverviewListenings', show: false }));
  }

  return true;
};

export const loadKpiOverviewByCountry = () => async (dispatch, getState) => {
  const { settings: { currentMonitoring } } = getState().listeningV2;
  if (currentMonitoring?.monitoring_id) {
    dispatch(setControlledLoader({ id: 'getOverviewByCountry', show: true }));

    const params = dispatch(getRequiredParamsToApi());
    const response = await api.loadKpiOverviewByCountry(params);
    const countries = response?.audience_filters || [];
    const mapCountries = countries.map((country) => ({
      countryCode: country?.audience_country,
      stats: {
        contents:
          (country?.stats?.posts_count || 0) +
          (country?.stats?.stories_count || 0) +
          (country?.stats?.reels_count || 0),
        emv:
          (country?.stats?.posts_emv || 0) +
          (country?.stats?.stories_emv || 0) +
          (country?.stats?.reels_emv || 0),
        engagement: country?.stats.total_engagement || 0,
      }
    }));
    dispatch(setShareOfVoiceByCountry(mapCountries));

    dispatch(setControlledLoader({ id: 'getOverviewByCountry', show: false }));
  }

  return true;
};

export const loadKpiEvolution = () => async (dispatch, getState) => {
  const {
    settings: {
      currentMonitoring,
      dateRanges: { startDate, endDate },
    },
    kpiEvolution: { rangeType },
  } = getState().listeningV2;

  if (currentMonitoring?.monitoring_id) {
    dispatch(setControlledLoader({ id: 'getKpiEvolution', show: true }));

    const availableTimeRanges = getTimeRangesByDates({ startDate, endDate })
    const validRangeType = !availableTimeRanges?.includes(rangeType)
      ? availableTimeRanges?.[0]
      : rangeType;

    const params = {
      ...dispatch(getRequiredParamsToApi()),
      interval: validRangeType,
      intervals: availableTimeRanges,
    };
    const response = await api.getKpiEvolution(params);

    dispatch(setKpiEvolution(response))

    dispatch(updateKpiFilters({
      target: 'kpiEvolution',
      filters: {
        rangeType: validRangeType,
        timeRanges: availableTimeRanges,
      }
    }))
    dispatch(setControlledLoader({ id: 'getKpiEvolution', show: false }));
  }
  return true;
}

export const loadKolDistribution = () => async (dispatch, getState) => {
  const { settings: { currentMonitoring } } = getState().listeningV2;

  if (currentMonitoring?.monitoring_id) {
    dispatch(setControlledLoader({ id: 'getKolsDistribution', show: true }));

    const params = dispatch(getRequiredParamsToApi());
    const response = await api.getKolDistribution(params);

    dispatch(setKolDistribution(response?.communities_size || {}))
    dispatch(setControlledLoader({ id: 'getKolsDistribution', show: false }));
  }
  return true;
}

export const getTopKols = () => async (dispatch, getState) => {
  const {
    settings: { currentMonitoring },
    topKols: { communitySize },
  } = getState().listeningV2;

  if (currentMonitoring?.monitoring_id) {
    dispatch(setControlledLoader({ id: 'getTopKolsListenings', show: true }));

    const params = {
      ...dispatch(getRequiredParamsToApi()),
      community_size: communitySize,
    };
    const response = await api.getTopKols(params);

    dispatch(setTopKols(response?.top_kols || []));

    dispatch(setControlledLoader({ id: 'getTopKolsListenings', show: false }));
  }
  return true;
}

export const getContents = () => async (dispatch, getState) => {
  const {
    listeningV2: {
      settings: { currentMonitoring },
      contents: {
        page,
        publication_type,
        sort_by,
      },
    },
  } = getState();

  if (currentMonitoring?.monitoring_id) {
    dispatch(setControlledLoader({ id: 'getListeningsContents', show: true }));

    const params = {
      ...dispatch(getRequiredParamsToApi()),
      page,
      per_page: POSTS_PER_PAGE,
      publication_type,
      sort_by,
    };
    const response = await api.getContents(params);

    dispatch(setContents({
      contents: response?.posts || [],
      total: response?.total || 0
    }));

    dispatch(setControlledLoader({ id: 'getListeningsContents', show: false }));
  }

  return true;
}

export const saveDashboard = ({
  id,
  monitoringIdFromUrl,
  isAnEdition,
  title,
  audienceLocations,
  myMention,
  mentionsToTrack,
  toastMessages,
}: DashboardSettingsModel & {
  isAnEdition: boolean;
  toastMessages: {
    created: { title: string; subtitle: string };
    error: { title: string };
    updated: { title: string };
  };
}): AppThunk<void> => async (dispatch, getState) => {
  const {
    ui: {
      modales: {
        [MODAL_ID]: modale,
      },
    },
  } = getState();

  const modalState: ModalData = modale.data

  // Prepare data
  const data = {
    title: title?.trim(),
    audienceLocations,
    myMention: {
      instagram: trimMentionForAPI(myMention.instagram) || null,
      tiktok: trimMentionForAPI(myMention.tiktok) || null,
    },
    mentionsToTrack: {
      instagram: trimMentionsForAPI(mentionsToTrack.instagram),
      tiktok: trimMentionsForAPI(mentionsToTrack.tiktok),
    }
  };

  // Validate form data
  const allMentions: {instagram: string[], tiktok: string[]} = {
    instagram: [
      ...(data.myMention.instagram ? [data.myMention.instagram] : []),
      ...data.mentionsToTrack.instagram,
    ],
    tiktok: [
      ...(data.myMention.tiktok ? [data.myMention.tiktok] : []),
      ...data.mentionsToTrack.tiktok,
    ]
  };

  const wrongFormatMentions: {instagram: string[], tiktok: string[]} = {
    instagram: allMentions.instagram.filter(mention => mention && !isMention(mention)),
    tiktok: allMentions.tiktok.filter(mention => mention && !isMention(mention)),
  };

  const duplicates: {instagram: string[], tiktok: string[]} = {
    instagram: findDuplicates(allMentions.instagram),
    tiktok: findDuplicates(allMentions.tiktok),
  };

  if (
    wrongFormatMentions.instagram.length ||
    wrongFormatMentions.tiktok.length ||
     duplicates.instagram.length ||
     duplicates.tiktok.length
  ) {
    dispatch(
      updateModal({
        id: MODAL_ID,
        datas: {
          data: {
            ...(modalState || {}),
            rejectedMentions: {
              ...(modalState?.rejectedMentions || { instagram: {}, tiktok: {} }),
              instagram: {
                ...(modalState?.rejectedMentions?.instagram || {}),
                ...wrongFormatMentions.instagram.reduce(
                  (prev, mention) => ({
                    ...prev,
                    [formatMentionForUI(mention)]: 'wrong_format',
                  }),
                  {},
                ),
                ...duplicates.instagram.reduce(
                  (prev, mention) => ({
                    ...prev,
                    [formatMentionForUI(mention)]: 'duplicated',
                  }),
                  {},
                ),
              },
              tikTok: {
                ...(modalState?.rejectedMentions?.tiktok || {}),
                ...wrongFormatMentions.tiktok.reduce(
                  (prev, mention) => ({
                    ...prev,
                    [formatMentionForUI(mention)]: 'wrong_format',
                  }),
                  {},
                ),
                ...duplicates.tiktok.reduce(
                  (prev, mention) => ({
                    ...prev,
                    [formatMentionForUI(mention)]: 'duplicated',
                  }),
                  {},
                ),
              }
            }
          },
        },
      }),
    );
    return false;
  }
  // end form validation

  // INFO: The backend needs to CREATE the dashboard object before testing the mentions.
  // If some mentions are rejected, we need to UPDATE the previously created dashboard
  // If the user closes the modal, we need to DELETE the previously created modal
  // We only consider the creation successful when all mentions are accepted (then show the success toast)
  dispatch(setControlledLoader({ id: MODAL_ID, show: true }));

  // Call API to create dashboard
  try {
    let response;

    // Fist time creating the dashboard
    if (!id) {
      response = await api.createDashboard(data);
    } else {
      // Update an already created dashboard
      response = await api.updateDashboard({
        id,
        ...data,
      });
    }
    // Handle response from creation or update
    if (
      response &&
      (!response.rejected_mentions ||
        (!Object.keys(response.rejected_mentions.instagram).length &&
          !Object.keys(response.rejected_mentions.tiktok).length))
    ) {
      toast(
        isAnEdition ? toastMessages.updated.title : toastMessages.created.title,
        {
          type: 'success',
          title: isAnEdition ? null : toastMessages.created.subtitle,
        },
      );
      dispatch(hideModal({ id: MODAL_ID }));
      void dispatch(loadListenings(monitoringIdFromUrl));
    } else {
      // there are errors during creation or in mentions
      dispatch(
        updateModal({
          id: MODAL_ID,
          datas: {
            data: {
              ...(modalState || {}),
              monitoringToEdit: {
                id: response.id,
                ...((modalState?.monitoringToEdit || {}) as Record<
                  string,
                  unknown
                >),
              },
              rejectedMentions: {
                instagram: response.rejected_mentions?.instagram
                  ? Object.entries(
                      response.rejected_mentions?.instagram,
                    )?.reduce(
                      (prev, [key, value]) => ({
                        ...prev,
                        [formatMentionForUI(key)]: value,
                      }),
                      {},
                    )
                  : {},
                tiktok: response.rejected_mentions?.tiktok
                  ? Object.entries(response.rejected_mentions?.tiktok)?.reduce(
                      (prev, [key, value]) => ({
                        ...prev,
                        [formatMentionForUI(key)]: value,
                      }),
                      {},
                    )
                  : {},
              },
            },
          },
        }),
      );
    }
  } catch (e) {
    toast(toastMessages.error.title, { type: 'error' });
    console.error(e);
  } finally {
    dispatch(setControlledLoader({ id: MODAL_ID, show: false }));
  }
};

export const saveAudienceLocations = ({
  id,
  audienceLocations
}: { id: number, audienceLocations: DashboardSettingsModel['audienceLocations'] }): AppThunk<void> => async (dispatch, getState) => {
  const { settings: { currentMonitoring }, monitorings } = getState().listeningV2;
  try {
    const locations = audienceLocations?.map(({ country, min }) => ({
      country,
      percentage: min,
    }));
    dispatch(updateListeningSettings({
      currentMonitoring: {
        ...currentMonitoring,
        audience_filters: locations
      }
    }));
    dispatch(updateListening({
      index: monitorings.findIndex(monitoring => monitoring.monitoring_id === id),
      data: {
        audience_filters: locations
      }
    }))
    await api.updateAudienceLocations({
      id,
      audienceLocations
    });
  } catch (e) {
    const intl = getIntl();
    toast(intl.formatMessage({ id: 'listening.toast.error.title' }), { type: 'error' });
    console.error(e);
  }
}

export const deleteDashboard = ({
  id,
}: {
  id: number;
}): AppThunk<void> => async () => {
  const intl = getIntl();
  try {
    await api.deleteDashboard({ id });
  } catch {
    toast(
      intl.formatMessage({ id: "listening.toast.error.title"}),
      { type: 'error' }
    );
  }
};

export const requestDemo = () => async () => {
  const intl = getIntl();
  try {
    await api.requestDemo();
    toast(
      intl.formatMessage({ id: "listening.placeholder.requestDemo.successToast"}),
      { type: 'success' }
    );
  } catch {
    toast(
      intl.formatMessage({ id: "listening.toast.error.title"}),
      { type: 'error' }
    );
  }
}

export const reloadMonitoringIfOpened =
  ({ monitoringIds }) =>
  (dispatch, getState) => {
    const openedMonitoringId =
      getState().listeningV2.settings.currentMonitoring?.monitoring_id;
    if (
      monitoringIds?.length > 0 &&
      openedMonitoringId &&
      monitoringIds.includes(Number(openedMonitoringId))
    ) {
      dispatch(loadListenings(openedMonitoringId));
    }
  };
