import { useEffect } from 'react';

function useCheckVersion() {
  useEffect(() => {
    const meta = document.querySelector('meta[name="build-timestamp"]');
    const buildTimestamp = meta?.getAttribute('content');
    const cachedTimestamp = localStorage.getItem('build_timestamp');

    if (cachedTimestamp && cachedTimestamp !== buildTimestamp) {
      localStorage.setItem('build_timestamp', buildTimestamp);
      console.log('This is a new version');
    } else {
      localStorage.setItem('build_timestamp', buildTimestamp);
    }
  }, []);
}

export default useCheckVersion;