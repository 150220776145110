import React, { useMemo, memo, useEffect, useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { ToastContainer, toast, Flip } from 'react-toastify';
import { Loader, Text } from 'kolkit';
import Appsignal from '@appsignal/javascript';
import Cookies from 'universal-cookie';

import useTrackingLogin from 'utils/hooks/useTrackingLogin';
import { conf, env, ENV_DEV } from 'config/env';
import { useDispatch } from 'utils/redux';
import { setClientToken } from 'actions/env';
import { getUserParams, logout } from 'actions/user';

import loadingSentences from 'locales/loadingSentences';
import GlobalErrorBoundary from 'components/GlobalErrorBoundary'

import Structure from './Structure';
import ProfileDrawer from './profileDetails/Drawer';
import PageNavigatorChecker from './dispatchers/PageNavigatorChecker';
import OutdatedBrowserAlert from './ui/OutdatedBrowserAlert';
import WarningNoInternet from './ui/WarningNoInternet';
import ToastCloseButton from './ui/ToastCloseButton';
import NewVersionRequired from './ui/NewVersionRequired';
import MaintenanceMode from './ui/MaintenanceMode';
import ForbiddenActionAlert from './ui/ForbiddenActionAlert';

import packageInfo from '../../package.json';

import 'css/scss/selections/selection.scss';
import 'react-toastify/dist/ReactToastify.min.css';


export const appsignal = new Appsignal({
  key: conf.appsignal,
  revision: packageInfo?.version,
  namespace: 'Influence',
});

const random = max => Math.floor(Math.random() * max);
const randomFromArray = arr => arr[random(arr?.length)];

const MainContainer = ({ user, locale, hasInternet }) => {
  const dispatch = useDispatch();

  const [mounted, setMounted] = useState(false);

  useTrackingLogin();

  useEffect(() => {
    // Getting Token from Cookies yeah
    const cookies = new Cookies();
    let cookieToken = cookies.get(conf.cookieToken);

    if (!cookieToken) {
      // No cookie set => redirection to login page
      // @ts-ignore
      if (!conf.overrideEnv && env !== ENV_DEV) void dispatch(logout());
      else {
        cookieToken = process.env.REACT_APP_COOKIE_TOKEN || 'dev-hashstrings';
      }
    }

    dispatch(setClientToken(cookieToken));

    void dispatch(getUserParams()).then((res) => {
      if (res) setMounted(true);
    });

    return () => {
      setMounted(false);
    };
    // eslint-disable-next-line
  }, []);

  const renderGlobalLoading = useMemo(
    () => {
      return (
        <div className="global-loader">
          <div className="loader">
            <div>
              <h1>
                <img
                  src="/logo/logo-horizontal-navy.svg"
                  className="logo"
                  alt="Kolsquare"
                />
              </h1>
            </div>
            <Loader className="global-loader_loader" padding={16} />
            <Text tag="h5" fontTitle>
              {randomFromArray(loadingSentences?.[locale])}
            </Text>
          </div>
        </div>
      );
    },
    [locale]
  );

  if (!mounted) return renderGlobalLoading;

  return (
    <>
      <CssBaseline />

      <GlobalErrorBoundary user={user}>
        <Structure
          user={user}
          locale={locale}
        />
      </GlobalErrorBoundary>

      {/* ERROR AND WARNINGS */}
      {!hasInternet && <WarningNoInternet />}
      <NewVersionRequired />
      <MaintenanceMode />
      <ForbiddenActionAlert />

      {/* Checkers */}
      <PageNavigatorChecker />

      <ProfileDrawer />
      <OutdatedBrowserAlert />

      <ToastContainer
        enableMultiContainer
        transition={Flip}
        autoClose={2000}
        closeButton={<ToastCloseButton />}
        toastClassName="toast"
        position={toast.POSITION.BOTTOM_CENTER}
        closeOnClick={false}
        pauseOnHover
        draggablePercent={40}
      />
    </>
  );
};

export default memo(MainContainer);
