import { useCallback, useMemo } from 'react';
import Paper from '@mui/material/Paper';
import { FormattedMessage } from 'react-intl-phraseapp';
import { Text, Button3, Icon, Truncate } from 'kolkit';

import { SavedSearch } from 'slices/search/searchEngine.slice';

import useFiltersByNetwork from '../../hooks/useFiltersByNetwork';
import styles from './SavedSearchItem.module.scss';

const SavedSearchItem: React.FC<{
  search: SavedSearch;
  onClick?: (searchId: SavedSearch['id']) => void;
  onClickAction?: (
    searchId: SavedSearch['id'],
    type: 'edit' | 'delete',
  ) => void;
}> = ({ search, onClick, onClickAction }) => {
  const { networkWithFilters } = useFiltersByNetwork(search);

  console.log(search)

  const numberOfFilters = useMemo(
    () =>
      Object.entries(search)
        .filter(
          ([key, value]) =>
            [
              'basic_filters',
              'profile_genders',
              'excluded_project_ids',
              'excluded_list_ids',
              'excluded_tag_ids',
              'profile_type',
              'exclude_favorites',
              'profile_filters',
              'instagram_filters',
              'youtube_filters',
              'tiktok_filters',
              'twitter_filters',
              'facebook_filters',
              'snapchat_filters',
            ].includes(key) &&
            value &&
            ((Array.isArray(value) && value?.length > 0) ||
            (typeof value === 'object' && Object.keys(value)?.length > 0) ||
            (typeof value === 'boolean' && value)),
        )
        .reduce((count, [, value]) => {
          if (value && !Array.isArray(value) && typeof value === 'object') {
            return count + Object.keys(value)?.length;
          }
          return count + 1;
        }, 0),
    [search],
  );

  const handleClick = useCallback(
    () => {
      if (onClick) onClick(search.id)
    },
    [onClick, search]
  );

  return (
    <Paper
      key={search.id}
      className={styles.card}
      elevation={0}
      onClick={handleClick}
    >
      <div className={styles.iconPlaceholder}>
        {networkWithFilters?.length === 1 ? (
          <Icon
            label={`${networkWithFilters[0]}-color`}
            theme="brand"
            size="big"
          />
        ) : (
          <Icon
            label="magnifying-glass"
            theme="solid"
            size="big"
            fill="#6C8CAD"
          />
        )}
      </div>
      <div className={styles.details}>
        <Text fontSize={16} fontWeight={600} resetMargin translate="no">
          <Truncate>{search.name}</Truncate>
        </Text>
        <div className={styles.filters} translate="no">
          <Truncate>{search.terms}</Truncate>
          <FormattedMessage
            id="engineSearch.filters.numberOfFilters"
            values={{ count: numberOfFilters }}
          />
        </div>
      </div>
      {!!onClickAction && (
        <div className={styles.actions}>
          <Button3
            icon="font"
            theme="tertiary"
            onClick={(e) => {
              e.stopPropagation();
              onClickAction(search.id, 'edit' as const);
            }}
            tooltip={<FormattedMessage id="global.cta.rename" />}
          />
          <Button3
            icon="trash-alt"
            theme="tertiary"
            onClick={(e) => {
              e.stopPropagation();
              onClickAction(search.id, 'delete' as const);
            }}
            tooltip={<FormattedMessage id="global.cta.delete" />}
          />
        </div>
      )}
    </Paper>
  );
};

SavedSearchItem.displayName = 'SavedSearchItem';

export default SavedSearchItem;
