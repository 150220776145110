import { formatPhoneNumberIntl } from 'react-phone-number-input';
import type { Value } from 'react-phone-number-input';

const formatPhoneNumberWithObfuscation = (phoneNumber: string) => {
  if (phoneNumber) {
    return formatPhoneNumberIntl(phoneNumber as Value)
      .split(' ')
      .reduce((formattedNumber, part, index, parts) => {
        if (index === 0 || index === parts.length - 1) {
          return [...formattedNumber, part];
        }
        return [...formattedNumber, part.replace(/./g, '*')];
      }, [] as string[])
      .join(' ');
  }
  return '';
};

export default formatPhoneNumberWithObfuscation;
