/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import {
  DataFromApi,
} from './nsmDashboard.types';
import {
  loadData,
  saveSetup,
} from './nsmDashboard.actions';
import { startLoading, stopLoading } from '../utils';

const initialState: DataFromApi & { loading: string[]} = {
  loading: [],
  error: '',
  lighthouse: {
    social_network_mentions: [],
  },
  results: {
    emv: null,
    impressions: null,
    engagement_rate: null,
    posts: null,
    kols: null,
  },
};

// Reducers
const nsmDashboardSlice = createSlice({
  name: 'nsmDashboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    // Save setup
      .addCase(saveSetup.pending, (state) => {
        startLoading(state, saveSetup)
      })
      .addCase(saveSetup.fulfilled, (state, action) => {
        const { lighthouse, results } = action.payload;
        state.lighthouse = lighthouse;
        state.results = results;
        stopLoading(state, saveSetup);
      })
      .addCase(saveSetup.rejected, (state) => {
        stopLoading(state, saveSetup);
      })

    // Load Data (setup, results and preferences filters)
      .addCase(loadData.pending, (state) => {
        startLoading(state, loadData)
      })
      .addCase(loadData.fulfilled, (state, action) => {
        const { lighthouse, results } = action.payload;
        state.lighthouse = lighthouse;
        state.results = results;
        stopLoading(state, loadData);
      })
      .addCase(loadData.rejected, (state) => {
        stopLoading(state, loadData);
        state = initialState;
      })
  }
});

export const { actions } = nsmDashboardSlice;

export default nsmDashboardSlice.reducer;
