import React, {
  memo,
  useCallback,
  useEffect,
  useState,
  useMemo,
  useRef
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl-phraseapp';
import cn from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import { Menu, MenuItem, Slide, ListItemIcon } from '@mui/material';

import { Icon, Spaces, Text } from 'kolkit';

import { logout, getUserLicence } from 'actions/user';
import routes from 'config/routes';
import { useDispatch, useSelector } from 'utils/redux';
import useLoading from 'utils/hooks/useLoading';

import styles from './UserBadge.module.scss';


const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="right" ref={ref} {...props} />;
});

const UserBadge = ({
  labelPosition,
  className,
  disabled,
  onOpen,
  fullWidth,
  themeCollapse,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const intl = useIntl();

  const [isCookiesConsentGiven, setIsCookiesConsentGiven] = useState(false);

  const positionRef = useRef(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const { loading } = useLoading('getUserLicence');

  const open = !!anchorEl;

  const { licence } = useSelector(({ user }) => ({
    licence: user?.profile?.licence
  }));

  const handleGetLicenceInfo = useCallback(
    () => licence?.isModerate
      ? dispatch(getUserLicence())
      : null
    ,
    [dispatch, licence]
  );

  const handleOpen = useCallback(
    event => {
      if (disabled) return false;
      handleGetLicenceInfo();
      if (onOpen) onOpen(!!event.currentTarget);
      setAnchorEl(event.currentTarget);
    },
    [disabled, onOpen, handleGetLicenceInfo]
  );

  const handleClose = useCallback(
    () => setAnchorEl(null),
    []
  );

  const handleLogout = useCallback(
    () => {
      handleClose();
      dispatch(logout());
    },
    [handleClose, dispatch]
  );

  const handleOpenCsPreferences = useCallback(() => {
    handleClose();
    window._iub?.cs?.api?.openPreferences();
  }, [handleClose]);

  const barStyle = useMemo(
    () => ({
      width: `${(licence?.usedDays * 100) / licence?.days}%`,
      backgroundColor: licence?.usedDays < (licence?.days - 10) ? '#728CA2' : '#f44336',
    }),
    [licence]
  );

  const useDaysStyle = useMemo(
    () => ({
      color: licence?.usedDays < (licence?.days - 10) ? 'var(--color-dominant-on-dominant)' : '#f44336'
    }),
    [licence]
  );

  const trigger = useMemo(
    () => {
      const cnTrigger = cn(styles.trigger, {
        [styles.moderate]: licence?.isModerate
      });

      return (
       <div className={cnTrigger} onClick={handleOpen} role="button">
         {licence?.isModerate && (
           <>
             <span>{licence?.remainingDaytime}</span>
             <Spaces bottom={2} />
             <div className={styles.bar}>
               <div className={styles.remainingTimeBar} style={barStyle} />
             </div>
             <Spaces bottom={4} />
           </>
         )}
         <Icon
           label="user-cog"
           size="huge"
           isButton={!licence?.isModerate}
           activeFill="var(--color-primary-400)"
           active={
              anchorEl || location.pathname.startsWith(`/${routes.myAccount}`)
            }
         />
       </div>
     )
    },
    [licence, location, handleOpen, barStyle, anchorEl]
  );

  useEffect(() => {
    try {
      setTimeout(() => {
        if (window._iub?.cs?.api) {
          setIsCookiesConsentGiven(window._iub.cs.api.isConsentGiven());
        }
      }, 1000);
    } catch (e) {
      console.error('[UserBadge] -> ', e);
    }
  }, []);

  const cnWrapper = cn(
    styles.wrapper,
    {
      [styles.fullWidth]: fullWidth,
      [styles.labelTop]: labelPosition === 'top'
    },
    className
  );

  return (
    <div className={cnWrapper}>
      <div className={styles.triggerWrapper} ref={positionRef}>
        {trigger}
        <Menu
          classes={{
            paper: styles.paper,
            list: styles.list,
          }}
          elevation={0}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          themeCollapse={themeCollapse}
          TransitionComponent={Transition}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 35,
            horizontal: -14,
          }}
          disableAutoFocusItem
        >
          {licence?.isModerate && (
            <div className={styles.licence}>
              <div className={styles.top}>
                <span className={styles.title}>
                  {intl.formatMessage({ id: 'navbar.licence.title' })}
                </span>
                <span className={styles.remainingTime}>
                  {loading ? (
                    <span>...</span>
                  ) : (
                    <span>
                      {intl.formatMessage(
                        { id: 'navbar.licence.tooltip' },
                        { time: licence?.remainingDaytime },
                      )}
                    </span>
                  )}
                </span>
              </div>

              <div className={styles.bottom}>
                <span>
                  <span className={styles.days}>
                    {intl.formatMessage(
                      { id: 'navbar.licence.usedDays' },
                      { count: licence?.usedDays },
                    )}
                  </span>
                  <b
                    style={useDaysStyle}
                  >{` ${licence?.usedDays}/${licence?.days}`}</b>
                </span>
                <div>
                  <div className={styles.bar}>
                    <div className={styles.remainingTimeBar} style={barStyle} />
                  </div>
                </div>
              </div>
            </div>
          )}

          <MenuItem
            onClick={handleClose}
            component={Link}
            to={`/${routes.myAccount}`}
          >
            <ListItemIcon style={{ minWidth: 28 }}>
              <Icon label="user-circle" size="big" />
            </ListItemIcon>
            <Text tag="span">
              <FormattedMessage id="navbar.myAccount" />
            </Text>
          </MenuItem>
          <MenuItem
            onClick={handleClose}
            component="a"
            href={intl.formatMessage({ id: 'navbar.legalMention.link' })}
            target="_blank"
            rel="noopener noreferrer"
          >
            <ListItemIcon style={{ minWidth: 28 }}>
              <Icon label="shield-check" size="big" />
            </ListItemIcon>
            <Text tag="span">
              <FormattedMessage id="navbar.legalMention.label" />
            </Text>
          </MenuItem>
          {isCookiesConsentGiven && (
            <MenuItem
              onClick={handleOpenCsPreferences}
              className="iubenda-cs-preferences-link"
            >
              <ListItemIcon style={{ minWidth: 28 }}>
                <Icon label="cookie" size="big" />
              </ListItemIcon>
              <Text tag="span">
                <FormattedMessage id="navbar.cookiePreferences.label" />
              </Text>
            </MenuItem>
          )}
          <MenuItem onClick={handleLogout}>
            <ListItemIcon style={{ minWidth: 28 }}>
              <Icon label="arrow-right-to-bracket" size="big" />
            </ListItemIcon>
            <Text tag="span">
              <FormattedMessage id="navbar.logout" />
            </Text>
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
};

export default memo(UserBadge);
