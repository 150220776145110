export const UPDATE_CURRENT_LISTENING = 'listeningV2::update::settings::currentMonitoring';
export const CLOSE_BANNER = 'listeningV2::update::settings::wantedMentionsStatus::null';
export const UPDATE_SETTINGS = 'listeningV2::update::settings';
export const RESET_SETTINGS = 'listeningV2::reset::settings';
export const LISTENINGS_LOADED = 'listeningV2::load::rows';
export const UPDATE_LISTENING = 'listeningV2::update::row';
export const OVERVIEW_LOADED = 'listeningV2::load::overview';
export const SET_TOP_KOLS = 'listeningV2::set::topKols';
export const SET_KPI_EVOLUTION = 'listeningV2::set::evolution';
export const SET_KOL_DISTRIBUTION = 'listeningV2::set::kol_distribution';
export const SET_SHARE_OF_VOICES = 'listeningV2::set::shareOfVoices';
export const SET_SHARE_OF_VOICES_BY_COUNTRY = 'listeningV2::set::shareOfVoicesByCountry';
export const SET_CONTENTS = 'listeningV2::set::contents';
export const UPDATE_KPI_FILTERS = 'listeningV2::update::filters';
