import { useEffect, useMemo, useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl-phraseapp';
import { Input2, Modal2, Text } from 'kolkit';

import useModal from 'utils/hooks/useModal';
import { useDispatch, useSelector } from 'utils/redux';

import { SavedSearch } from 'slices/search/searchEngine.slice/searchEngine.types';
import {
  deleteSearch,
  fetchSavedSearches,
  saveSearch,
} from 'slices/search/searchEngine.slice';
import renderModal from 'utils/HOCs/renderModal';
import SavedSearchItem from '../../components/SavedSearchItem';

import styles from './SavedSearchesModal.module.scss';

export const MODAL_ID = 'searchEngine.savedSearchModal';

const SavedSearchesModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { show, off, data } = useModal<{
    search: SavedSearch;
    type: 'edit' | 'save' | 'delete';
  }>(MODAL_ID);

  const { loading, saved_searches } = useSelector(
    ({
      search: {
        searchEngine: { loading, saved_searches },
      },
    }) => ({
      loading,
      saved_searches,
    }),
  );

  const [name, setName] = useState('');
  const [hasError, setHasError] = useState(false);

  const handleChangeInput = useCallback(
    ({ value }) => setName(value),
    []
  );

  const handleClickPreviouslySaved = useCallback(
    (searchId) => {
      const previousSearch = saved_searches.find(
        (search) => search.id === searchId,
      );
      if (!previousSearch) return null;
      dispatch(
        saveSearch({
          params: {
            ...data?.search,
            name: previousSearch.name,
            id: previousSearch.id,
          },
        }),
      )
        .then(() => off())
        .catch(() => setHasError(true));
    },
    [data?.search, dispatch, off, saved_searches],
  );

  const handleSaveSearchSuccess = useCallback(() => {
    off();
    void dispatch(fetchSavedSearches());
  }, [dispatch, off]);

  const actions = useMemo(
    () => ({
      primary: {
        title: intl.formatMessage({
          id:
            data?.type === 'delete'
              ? 'global.cta.yesDelete'
              : data?.type === 'edit'
                ? 'global.cta.update'
                : 'modal.savedSearch.saveNew',
        }),
        onClick: async () => {
          if (data?.type === 'delete') {
            await dispatch(
              deleteSearch({
                ...data?.search,
              }),
            )
              .then(handleSaveSearchSuccess)
              .catch(() => setHasError(true));
          } else {
            await dispatch(
              saveSearch({
                params: {
                  ...data?.search,
                  id: null, // Force to create a new search
                  name,
                },
                isUpdate: data?.type === 'edit',
              }),
            )
              .then(handleSaveSearchSuccess)
              .catch(() => setHasError(true));
          }
        },
        disabled: data?.type !== 'delete' && !name?.trim().length,
        dataId: 'engine-saved-search-save',
      },
      secondary: {
        title: intl.formatMessage({ id: 'global.cta.cancel' }),
        onClick: off,
      },
    }),
    [
      intl,
      data?.type,
      data?.search,
      name,
      off,
      dispatch,
      handleSaveSearchSuccess,
    ],
  );

  useEffect(() => {
    if (data?.type === 'edit' && data?.search?.name) {
      setName(data.search.name);
    }
  }, [data?.search, data?.type]);

  useEffect(() => {
    if (data?.type === 'save') {
      void dispatch(fetchSavedSearches());
    }
  }, [data?.type, dispatch]);

  const isLoading =
    loading.includes(saveSearch.typePrefix) ||
    loading.includes(deleteSearch.typePrefix);

  return (
    <Modal2
      title={intl.formatMessage({
        id: `modal.savedSearches.${data?.type || 'edit'}.title`,
      })}
      on={show}
      onClick={off}
      action={actions}
      loading={isLoading}
    >
      {(data?.type === 'edit' || data?.type === 'save') && (
        <Input2
          value={name}
          placeholder={intl.formatMessage({
            id: 'modal.savedSearch.edit.name',
          })}
          name="title"
          onChange={handleChangeInput}
          fullWidth
          required
          disabled={isLoading}
          error={hasError}
          errorMessage={intl.formatMessage({
            id: 'global.messages.error.required',
          })}
          dataId="engine-saved-search-name"
          autoFocus
        />
      )}
      {data?.type === 'delete' && (
        <Text resetMargin>
          <FormattedMessage id="modal.savedSearches.delete.description" />
        </Text>
      )}
      {saved_searches?.length > 0 && data?.type === 'save' && (
        <div className={styles.savedSearches}>
          <Text fontWeight={500} resetMargin>
            <FormattedMessage id="global.saveAs" />
          </Text>
          {saved_searches.map((savedSearch) => {
            return (
              <SavedSearchItem
                key={savedSearch.id}
                search={savedSearch}
                onClick={handleClickPreviouslySaved}
              />
            );
          })}
        </div>
      )}
    </Modal2>
  );
};

export default renderModal(MODAL_ID, SavedSearchesModal);
