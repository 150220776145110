import React, { memo, useMemo, useEffect, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'utils/redux';

import './CookiesApprobation.scss';

const siteId = 3819780;

const CookiesApprobation = () => {
  const { locale, loading } = useSelector(
    ({ env: { locale }, ui: { loading } }) => ({
      locale,
      loading,
    }),
  );

  const params = useMemo(() => {
    let cookiePolicyId: number;
    let lang: 'fr' | 'de' | 'it' | 'es' | 'en-GB';
    switch (locale) {
      case 'fr-FR':
        cookiePolicyId = 84199239;
        lang = 'fr';
        break;
      case 'de-DE':
        cookiePolicyId = 44848219;
        lang = 'de';
        break;
      case 'es-ES':
        cookiePolicyId = 57529718;
        lang = 'es';
        break;
      case 'it-IT':
        cookiePolicyId = 37815836;
        lang = 'it';
        break;
      case 'en-GB':
      default:
        cookiePolicyId = 39562135;
        lang = 'en-GB';
        break;
    }
    return {
      siteId,
      cookiePolicyId,
      lang,
    };
  }, [locale]);

  const loadScript = useCallback(() => {
    const loader = () => {
      const s = document.createElement('script');
      const tag = document.getElementsByTagName('script')[0];
      s.src = '//cdn.iubenda.com/cs/iubenda_cs.js';
      tag.parentNode?.insertBefore(s, tag);
    };
    loader();
  }, []);

  // init cookie banner with params
  useEffect(() => {
    if (!(window as any)._iub) (window as any)._iub = [];
    (window as any)._iub.csConfiguration = {
      siteId: params.siteId,
      cookiePolicyId: params.cookiePolicyId,
      lang: params.lang,
      storage: { useSiteId: true },
      floatingPreferencesButtonDisplay: false,
      banner: {
        logo: '/logo/logo-horizontal-white.svg',
      }
    };

    if (!loading) {
      loadScript();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  // Manage banner height relative to footer
  useEffect(() => {
    const hasFooter = !!document.querySelector('footer[class^=Footer');
    if (!hasFooter) return;

    setTimeout(() => {
      const id = 'iubenda-custom-styles';
      if (document.getElementById(id)) return;
      const style = document.createElement('style');
      style.id = id;
      const head = document.getElementsByTagName('head')[0];
      style.innerHTML = `
        #iubenda-cs-banner.iubenda-cs-slidein.iubenda-cs-visible .iubenda-cs-container {
          transform: translateY(-52px) !important;
        }
      `;
      head.appendChild(style);
      // Because it needed to be loaded after iubenda scripts, need to wait a bit
    }, 1000);
  }, []);

  return (
    <Helmet key={locale}>
      <script
        key={locale}
        type="text/javascript"
        src={`https://cs.iubenda.com/autoblocking/${siteId}.js`}
      />
      <script
        key={locale}
        type="text/javascript"
        src="//cdn.iubenda.com/cs/gpp/stub.js"
      />
    </Helmet>
  );
};

export default memo(CookiesApprobation);
