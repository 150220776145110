import moment from 'moment';
import { createAction } from 'redux-actions';
import _orderBy from 'lodash.orderby';
import _isNil from 'lodash.isnil';
import _get from 'lodash.get';

import { startActionTimeout } from 'actions/ui';
import { TIME_BETWEEN_EXPORT } from 'config/campaigns';
import { ADD_POST_PER_PAGE } from 'config/posts';
import routes from 'config/routes';
import { getIntl } from 'utils/HOCs/IntlGlobalSingleton';
import { initialState, initialDiscountCodesState } from 'reducers/views'
import { celebrityNameFormatter } from 'utils';
import toast from 'utils/toast';
import getLocalStorageItem from 'utils/getLocalStorageItem';

import * as CAMPAIGNS from 'constants/campaigns';
import * as api from 'api/campaigns';
import * as mapper from 'mappers/campaigns';

import * as actionsCampaigns from './campaigns';
import * as actionsViews from './views';
import * as actionsUi from './ui';

export const getPostsActions = createAction(CAMPAIGNS.GET_POSTS);
export const resetPosts = createAction(CAMPAIGNS.RESET_POSTS);

export const resetCampaignDetails = dispatch => {
  dispatch(actionsViews.update({
    view: 'campaignDetails',
    ...initialState.campaignDetails
  }));
}

/* Sort profile stats
 * by: 'name', 'publications', 'campaignAverageCommitment', 'investedBudget', 'emv', 'roi', 'reach', 'impressions', 'likes', 'comments', 'views', 'swipeUp', 'clicks', 'shares',
 * order: 'descending', 'ascending'
 * return ids sorted
*/
export const getProfilesIdsSorted = ({profiles, profilesStats, by, order}) => {
  const getProfileName = (profile) => {
    if (!profile || (profile && !profile.core)) return null;

    const { pseudo, fullName } = profile.core;
    return !pseudo ? fullName : pseudo;
  }
  const iteratees =
    by === 'name'
      ? [
          (id) =>
            !!getProfileName(profiles[id]) &&
            getProfileName(profiles[id]).trim().toLowerCase(),
        ]
      : [
          (id) => {
            if (Array.isArray(profilesStats[id])) {
              if (profilesStats[id].length === 0) return 0;
              if (profilesStats[id].length === 1)
                return profilesStats[id][0][by];

              // numbers
              if (typeof profilesStats[id][0][by] === 'number')
                return profilesStats[id].reduce((sum, val) => sum + val[by], 0);

              // strings
              // if (typeof profilesStats[id][by][0] === "string") return profilesStats[id][by].sort((a, b) => {
              //   return a.localeCompare(b);
              // })

              // default to first value
              return profilesStats[id][0][by];
            }
            // We consider the value null as zero for the sort
            return profilesStats[id][by] !== null ? profilesStats[id][by] : 0;
          },
        ];

  const orderedProfilesIds = _orderBy(Object.keys(profilesStats), iteratees, [order === "descending" ? "desc" : "asc"]);

  return orderedProfilesIds?.map(id => Number(id));
};

export const loadReportingProfileTags = campaignId => async dispatch => {
  const profileTags = await actionsCampaigns.getReportingProfileTags(campaignId);
  dispatch(
    actionsViews.updateKey({
      view: 'campaignDetails',
      key: 'reporting',
      profileTags,
    })
  );
}

export const getReport = (campaignId) => async (dispatch, getState) => {
  // getting search params from view & campaign infos
  const { campaigns, views, profiles } = getState();
  const campaign = campaigns[campaignId];

  const useOldEstimation = getLocalStorageItem(
    `use_old_estimations_${campaignId}`,
    false,
  );

  dispatch(
    actionsViews.updateKey({
      view: 'campaignDetails',
      key: 'reporting',
      loading: true,
    })
  );

  dispatch(actionsViews.update({
    view: 'campaignDetails',
    id: campaignId
  }));

  const {
    globalStats,
    posts,
    profilesStats,
    totalPosts,
    exportPptxLoading,
    kols,
  } = await dispatch(
    actionsCampaigns.getReporting({
      campaignId,
      upgraded_estimations: !useOldEstimation,
      ...views.campaignDetails.reporting.filters,
      ...campaign.reporting,
    }),
  );

  const { mainTable, affilaeTable } = views.campaignDetails.reporting.profilesSorted;

  const profilesSorted = {
    mainTable: {
      ...mainTable,
      ids: getProfilesIdsSorted({
        profiles,
        profilesStats,
        by: mainTable?.by,
        order: mainTable?.order
      }),
    },
    affilaeTable: {
      ...affilaeTable,
      ids: getProfilesIdsSorted({
        profiles,
        profilesStats,
        by: affilaeTable?.by,
        order: affilaeTable?.order
      }),
    },
  };

  // Hack to avoid bad merge and don't know why..
  dispatch(
    actionsViews.updateKey({
      view: 'campaignDetails',
      key: 'reporting',
      posts: [],
      kols,
    }),
  );

  dispatch(
    actionsViews.updateKey({
      view: 'campaignDetails',
      key: 'reporting',
      globalStats: {
        ...globalStats,
        override: true,
      },
      profilesStats: {
        ...profilesStats,
        override: true,
      },
    }),
  );

  dispatch(
    actionsViews.updateKey({
      view: 'campaignDetails',
      key: 'reporting',
      posts,
      totalPosts,
      exportPptxLoading,
      profilesSorted,
      loading: false,
    }),
  );
}

export const getReportContent = ({ campaignId, page }) => async (dispatch, getState) => {
  // getting search params from view & campaign infos
  const { campaigns, views } = getState();
  const campaign = campaigns[campaignId];

  dispatch(
    actionsViews.updateKey({
      view: 'campaignDetails',
      key: 'reporting',
      loading: true,
    })
  );

  dispatch(actionsViews.updateKey({
    view: 'campaignDetails',
    key: 'reporting',
    filters: {
      ...views.campaignDetails.reporting.filters,
      page,
    }
  }));

  const {
    posts,
    totalPosts,
  } = await dispatch(
    actionsCampaigns.getReporting({
      campaignId,
      ...views.campaignDetails.reporting.filters,
      ...campaign.reporting,
      page,
      scopes: 'posts'
    }),
  );

  await dispatch(
    actionsViews.updateKey({
      view: 'campaignDetails',
      key: 'reporting',
      posts: page === 0
        ? posts
        : [...views.campaignDetails.reporting.posts, ...posts],
      totalPosts,
    }),
  );

  dispatch(
    actionsViews.updateKey({
      view: 'campaignDetails',
      key: 'reporting',
      loading: false,
    })
  );
}

export const updateSavedFilter = ({ campaignId, key, value }) => async (dispatch, getState) => {
  const { campaigns, views } = getState();
  const campaign = campaigns[campaignId];

  const reporting = Object.assign(
    {
      ...campaign.reporting,
    },
    key === 'search' && { search: value },
    key === 'dates' && {
      startDate: value?.startDate,
      endDate: value?.endDate,
    },
  );

  const core = Object.assign(
    { updatedAt: new Date().toISOString() },
    key === 'search' && {
      mentions: value.filter(m => m[0] === '@'),
      hashtags: value.filter(m => m[0] === '#'),
      keywords: value.filter(m => m[0] !== '@' && m[0] !== '#'),
    },
    key === 'dates' && {
      startDate: value?.startDate,
      endDate: value?.endDate,
    }
  );

  dispatch(actionsCampaigns.update({
    id: campaignId,
    reporting,
    core
  }));

  dispatch(actionsViews.updateKey({
    view: 'campaignDetails',
    key: 'reporting',
    filters: {
      ...views.campaignDetails.reporting.filters,
      page: 0,
    }
  }));

  if (key === 'search' && value?.length === 0) {
    // force update campagne on remove all hashtags/mentions
    await api.updateCampaign({
      campaignId,
      mentions: [],
      hashtags: [],
      keywords: [],
    });
  }

  if (key === 'dates' && (!campaign.reporting?.search || campaign.reporting.search?.length === 0)) {
    // force update campagne without hashtags/mentions on edit dates
      await api.updateCampaign({
        campaignId,
        startDate: value?.startDate,
        endDate: value?.endDate,
      });
  }
}

export const updateFilter =  ({ campaignId, filter, value }) => async (dispatch, getState) => {
  const { filters } = getState().views.campaignDetails.reporting;

  dispatch(actionsViews.updateKey({
    view: 'campaignDetails',
    key: 'reporting',
    filters: {
      ...filters,
      page: 0,
      [filter]: value,
    }
  }));

  dispatch(startTimerGetReport(campaignId));
}

export const updateFilters = ({ campaignId, filters: newFilters }) => async (dispatch, getState) => {
  const { filters } = getState().views.campaignDetails.reporting;

  await dispatch(actionsViews.updateKey({
    view: 'campaignDetails',
    key: 'reporting',
    filters: {
      ...filters,
      ...newFilters,
      posts: [],
      page: 0,
    }
  }));

  dispatch(startTimerGetReport(campaignId));
}

export const updateStoreFilters =  ({ filters: newFilters }) => (dispatch, getState) => {
  const { filters } = getState().views.campaignDetails.reporting;

  dispatch(actionsViews.updateKey({
    view: 'campaignDetails',
    key: 'reporting',
    filters: {
      ...filters,
      ...newFilters,
      posts: [],
      page: 0,
    }
  }));
}

export const resetReportingFilter = () => dispatch => {
  dispatch(
    actionsViews.updateKey({
      view: 'campaignDetails',
      key: 'reporting',
      filters: _get(initialState, 'campaignDetails.reporting.filters'),
    }),
  );
};

export const startTimerGetReport = campaignId => async dispatch => {
  dispatch(startActionTimeout(() => {
    dispatch(getReport(campaignId));
    dispatch(
      actionsCampaigns.getCampaignProfiles({
        campaignId,
        filters: {
          page: 0,
          per_page: 10000,
        },
      }),
    );
    dispatch(actionsCampaigns.getAllTrackingLinks(campaignId));
    dispatch(actionsCampaigns.getDiscountCodes(campaignId));
  }), 500);
}

const setExportPptxLoading = () => async (dispatch, getState) => {
  const {user:{profile:{id}}} = getState();

  dispatch(actionsViews.updateKey({
    view: 'campaignDetails',
    key: "reporting",
    exportPptxLoading : {
      id
    },
  }))
}

export const exportReporting = ({ campaignId, formatType }) => async (dispatch, getState) => {
  const { views } = getState();

  const intl = getIntl();

  const { exportPptxLoading } = views?.campaignDetails?.reporting;

  // IF last ppt generation is less than 10 min, abort!!!
  if (formatType === 'pptx') {
    const key = `last-pptx-export-${campaignId}`;
    const lastExportForthisCampaign = window.localStorage.getItem(key) || null;

    if (
      lastExportForthisCampaign &&
      moment(lastExportForthisCampaign).isSameOrAfter(
        moment().subtract(TIME_BETWEEN_EXPORT, 'minutes'),
      )
    ) {
      toast(
        intl.formatMessage({ id: "toasts.exportCampaignBlocked.message"}),
        {
          title: intl.formatMessage({ id: "toasts.exportCampaignBlocked.title"}),
          type: 'error',
        }
      );
      return null;
    }
    window.localStorage.setItem(key, new Date().toISOString());
  }

  const res = await dispatch(actionsCampaigns.exportReporting({
    campaignId,
    formatType,
    exportPptxLoading
  }))

  const getAlertType = () => {
    if (res?.status === 422 && res?.error) return 'error';
    return 'success';
  }

  const alertType = getAlertType();

  dispatch(actionsUi.showModal({
    id: 'exportMonitor',
    data: {
      type: formatType,
      alertType,
      exportType: 'reporting',
      exportPptxLoading
    }
  }))

  if (formatType === 'pptx' && !exportPptxLoading && alertType === 'success') {
    dispatch(setExportPptxLoading());
    toast(
      intl.formatMessage({ id: "toasts.exportCampaignStarted.message"}),
      {
        title: intl.formatMessage({ id: "toasts.exportCampaignStarted.title"}),
        type: 'success',
      }
    );
  }
}

export const toggleSelectPost = postId => (dispatch, getState) => {
  const {views: {campaignDetails: { reporting }} } = getState();
  const selectedPosts = reporting?.selectedPosts?.includes(postId)
    ? reporting?.selectedPosts?.filter(id => id !== postId)
    : [...reporting?.selectedPosts, postId]

  dispatch(actionsViews.updateKey({
    view: 'campaignDetails',
    key: 'reporting',
    selectedPosts,
  }));
}

export const clearSelectedPosts = () => dispatch => {
  dispatch(actionsViews.updateKey({
    view: 'campaignDetails',
    key: 'reporting',
    selectedPosts: [],
  }));
}

export const promptAddPosts = campaignId => dispatch => {
  dispatch(actionsUi.showModal({
    id: 'addPosts',
    campaignId,
  }))
}

export const openShareLinkModal = () => async (dispatch, getState) => {
  const {campaigns, views: {campaignDetails: {id}} } = getState();

  const campaign = campaigns[id];
  const isCreate = _isNil(campaign.reporting.shareToken);

  if (isCreate) {
    await dispatch(actionsCampaigns.generateShareToken(id));
  }

  dispatch(actionsUi.showModal({
    id: 'shareMonitor',
    isCreate,
  }))
}

export const disableShareLink = () => async dispatch => {
  await dispatch(actionsCampaigns.disableShareLink());
}

export const getPosts = query => async (dispatch, getState) => {
  const { campaigns } = getState();

  if (!query.influencerId) return null;

  dispatch(
    actionsViews.updateKey({
      view: 'campaignDetails',
      key: 'reporting',
      loading: true,
    })
  );

  const { reporting } = campaigns?.[query.campaignId];

  const fullQuery = {
    ...reporting,
    ...query,
  };

  const response = await api.getProfilePosts(fullQuery);

  const { totalPosts, posts } = mapper.getProfilePosts.fromApi(response);

  if (posts) {
    dispatch(getPostsActions({
      campaignId: query.campaignId,
      total: totalPosts,
      posts,
      page: query.page
    }))
  }

  dispatch(
    actionsViews.updateKey({
      view: 'campaignDetails',
      key: 'reporting',
      loading: false,
    })
  );

  return true;
}

export const getKolsPosts = query => async (dispatch, getState) => {
  const { campaigns, views } = getState();

  const { profiles, types, networks } = views.campaignDetails.reporting.filters;
  const { id } = views.campaignDetails;

  if ((!profiles || !profiles?.length) && !id) return null;

  dispatch(
    actionsViews.updateKey({
      view: 'campaignDetails',
      key: 'reporting',
      loading: true,
    })
  );

  const { reporting } = campaigns?.[id];

  const fullQuery = {
    ...reporting,
    ...query,
    campaignId: id,
    influencerId: profiles,
    page: query.page,
    perPage: ADD_POST_PER_PAGE,
    types,
    networks
  };

  const response = await api.getProfilePosts(fullQuery);

  const { totalPosts, posts } = mapper.getProfilePosts.fromApi(response)

  if (posts) {
    dispatch(getPostsActions({
      campaignId: id,
      total: totalPosts,
      posts,
      page: query.page
    }))
  }

  dispatch(
    actionsViews.updateKey({
      view: 'campaignDetails',
      key: 'reporting',
      loading: false,
    })
  );

  return true;
}

export const addPostsToReporting = query => async dispatch => {
  await dispatch(actionsCampaigns.addPostsToReporting(query));
  // Reload reporting
  dispatch(getReport(query.campaignId));
}


/* Modale ask remove profile from campaign */
export const askRemoveProfilesFromCampaign = ({
  campaignIds,
  profileIds,
  from,
  fromMultiSelect,
  onConfirm: onConfirmCallback,
}) => dispatch => {
  const onConfirm = () => {
    dispatch(
      actionsCampaigns.removeProfilesFromCampaigns({
        from,
        profileIds,
        campaignIds,
        fromMultiSelect,
      }),
    );
    dispatch(actionsUi.hideModal({ id: 'confirmRemoveProfileFromList' }));
    if(onConfirmCallback) onConfirmCallback();
  };

  dispatch(
    actionsUi.showModal({
      id: 'confirmRemoveProfileFromList',
      onConfirm,
      profileIds,
      campaignIds,
    }),
  );
};

/* Modale ask remove campaign */
export const askRemoveCampaign = id => async (dispatch, getState) => {
  const { campaigns } = getState();
  const campaign = campaigns[id];

  const {name, isMine, createdBy} = campaign.core;

  const onConfirm = () => {
    dispatch(actionsCampaigns.removeCampaign(id));
    dispatch(actionsUi.hideModal({id: 'confirmRemoveCampaign'}));
  }

  dispatch(actionsUi.showModal({
    id: 'confirmRemoveCampaign',
    data: {
      onConfirm,
      label: name,
      isMine,
      createdBy
    },
  }))
}

export const promptRemovePostsFromReporting = ({ campaignId, postsIds}) => async (dispatch, getState) => {
  const { campaigns, views, profiles } = getState();
  const campaignName = campaigns?.[campaignId]?.core?.name;
  const post = views.campaignDetails.reporting.posts.find(p => p.id === postsIds[0]);
  const profile = profiles?.[post?.profileId];

  dispatch(actionsUi.showModal({
    id: 'confirmRemoveMedia',
    data: {
      campaignName,
      postsIds,
      profileName: celebrityNameFormatter(profile?.core),
      onConfirm: async () => {
        await api.removePostFromReporting({ campaignId, postsIds });
        dispatch(clearSelectedPosts());
        // Reload reporting
          dispatch(getReport(campaignId));
      },
    }
  }));
}

export const showEditCampaignForm = ({ campaignId, target }) => (dispatch, getState) => {
  const onConfirmCampaign = async data => {
    if (!data?.created) return null;

    dispatch(actionsUi.updateModal({
      id: 'campaignModalForm',
      datas: {
        loading: true,
      },
    }));

    await dispatch(actionsCampaigns.updateCampaign({
      id: campaignId,
      data: data.created,
    }));

    // update campaign reporting: 'search', 'startDate', 'endDate'
    const { campaigns } = getState();
    const campaign = campaigns[campaignId];
    const {startDate, endDate, mentions, hashtags, keywords} = data.created;

    const reporting = {
      ...campaign.reporting,
      search: [...mentions, ...hashtags, ...keywords],
      startDate,
      endDate
    };

    dispatch(actionsCampaigns.update({
      id: campaignId,
      reporting
    }));

    dispatch(actionsUi.hideModal({id: 'campaignModalForm'}));

    if (window.location.pathname.indexOf(routes.reporting) > -1) dispatch(startTimerGetReport(campaignId));
  }

  dispatch(actionsUi.showModal({
    id: 'campaignModalForm',
    onConfirmCampaign,
    campaignIdToEdit: campaignId,
    target,
  }));
}

export const updateSortProfilesReporting = ({ by, order, tableKey = 'mainTable' }) => (dispatch, getState) => {

  const { profiles, views } = getState();
  const { profilesStats, trackingLinks, discountCodes } = views.campaignDetails.reporting;

  const profilesSorted = {
    [tableKey]: {
      by,
      order,
      ids: getProfilesIdsSorted({
        profiles,
        by,
        order,
        profilesStats: tableKey === 'trackinkLinksTable'
          ? trackingLinks?.profiles
          : tableKey === 'discountCodesTable'
            ? discountCodes?.profiles
            : profilesStats,
      }),
    }
  }

  dispatch(actionsViews.updateKey({
    view: 'campaignDetails',
    key: 'reporting',
    profilesSorted,
  }));
}

export const updateDiscountCodes = ({ key, value }) => (dispatch, getState) => {
  const { newDiscountCodes } = getState()?.views?.campaignDetails?.shopify;
  dispatch(actionsViews.updateKey({
    view: 'campaignDetails',
    key: 'shopify',
    newDiscountCodes: {
      ...newDiscountCodes,
      [key]: value,
    }
  }));
}

export const resetDiscountCodes = () => dispatch => {
  dispatch(actionsViews.updateKey({
    view: 'campaignDetails',
    key: 'shopify',
    newDiscountCodes: initialDiscountCodesState,
  }));
}

export const getForecastFollowers = ({ campaignId, network, profilesIds }) => async (dispatch) => {
  dispatch(actionsUi.setControlledLoader({ id: 'getForecastFollowers', show: true }));
  const response = await api.getForecastFollowers({ campaignId, network, profilesIds})
  dispatch(actionsUi.setControlledLoader({ id: 'getForecastFollowers', show: false }));
  return response;
};

export const getForecastStats = ({ campaignId, network, profilesIds, publicationType }) => async (dispatch) => {
  dispatch(actionsUi.setControlledLoader({ id: 'getForecastStats', show: true }));
  const response = await api.getForecastStats({ campaignId, network, profilesIds, publicationType });
  dispatch(actionsUi.setControlledLoader({ id: 'getForecastStats', show: false }));
  return response;
};

export const addPostsByURL = ({ campaignId, urls }) => async (dispatch) => {
  dispatch(actionsUi.setControlledLoader({ id: 'addPostsByURL', show: true }));
  const response = await api.addPostsByURL({ campaignId, urls });
  dispatch(actionsUi.setControlledLoader({ id: 'addPostsByURL', show: false }));
  return response;
}