import FingerprintJS from '@fingerprintjs/fingerprintjs'
import Cookies from 'universal-cookie';

import { conf } from 'config/env';
import * as authApi from 'api/auth';
import * as authMapper from 'mappers/auth';
import { MINIMUM_PASSWORD_LENGTH } from 'constants/auth';
import { DOUBLE_AUTHENTICATION_MODAL_ID, DoubleAuthenticationStep } from 'components/modules/DoubleAuthentication/constants';
import toast from 'utils/toast';
import { getIntl } from 'utils/HOCs/IntlGlobalSingleton';

import { showModal } from './ui';


export const goToAppHomePage = () => {
  window.location.href = conf.urls.app;
};

export const verifyToken = ({ tokenType, token }) => async () => {
  if (!token) return { error: 'tokenNotFound' };
  try {
    const requestResponse = await authApi.verifyToken({
      tokenType,
      token
    });
    return requestResponse;
  } catch (err) {
    return { error: 'unknown' };
  }
}

export const login = ({ email = '', password = '', otp_user_id, otp_attempt }) => async (dispatch) => {
  try {
    const fingerprint = (await FingerprintJS.load().then((fp) => fp.get()))
      .visitorId;
    const {
      error,
      hasCommunityAccess,
      otp_user_id: response_otp_user_id,
      otp_method,
      must_enter_phone,
      phone,
    } = await authApi.login(
      Object.assign(
        {
          fingerprint,
        },
        email && password && { email, password }, // In case of simple login
        otp_user_id && otp_attempt && { otp_user_id, otp_attempt }, // in case of double auth step
      ),
    );

    /**
     * ⚠️⚠️ IMPORTANT ⚠️⚠️
     * disallow allow KOLs access to Influence
     *
     */
    if (hasCommunityAccess) {
      const cookies = new Cookies();
      cookies.remove(conf.cookieToken, { path: '/', domain: conf.cookieHostname, sameSite: true });
      return { error: 'invalidEmailPassword' };
    }
    if (error) return { error };
    // INFO: Not logged but we receive and otp user id, so should activate double auth modal
    if (response_otp_user_id) {
      const step = otp_method === 'phone' && must_enter_phone
        ? DoubleAuthenticationStep.ENTER_PHONE
        : otp_method === 'phone'
          ? DoubleAuthenticationStep.CODE_VERIFICATION_BY_SMS
          : DoubleAuthenticationStep.CODE_VERIFICATION_BY_APP
      dispatch(
        showModal({
          id: DOUBLE_AUTHENTICATION_MODAL_ID,
          data: {
            otp_user_id: response_otp_user_id,
            otp_method,
            phoneNumber: phone,
            step,
          }
        }),
      );
    } else {
      goToAppHomePage();
    }
    return { sucess: true };
  } catch (err) {
    console.error(`login error: ${err}`)
    return { error: 'unknown' };
  }
}

export const validateDoubleAuthentication = ({ otp_attempt }) => async () => {
  try {
    if (!otp_attempt) throw new Error('Invalid params');

    const { error, error_code } = await authApi.validateDoubleAuthentication({
      otp_attempt,
    });
    if (error || error_code) return { error, error_code };
    return { sucess: true };
  } catch (error) {
    return { error };
  }
};

export const setupDoubleAuthentication =
  ({ method, phoneNumber = '', active = true, otp_user_id = 0 }) =>
  async () => {
    try {
      if (method === 'phone' && !phoneNumber?.trim()) {
        throw new Error('Invalid phone number');
      }
      const params = Object.assign(
        { method, active },
        method === 'phone' && { phoneNumber },
        otp_user_id && { otp_user_id }
      );
      const response = await authApi.setupDoubleAuthentication(params);
      const { error, payload, qr_code_png } = response;
      if (error) return { error, error_code: payload?.error_code };
      return { sucess: true, qr_code_png };
    } catch (error) {
      return { error };
    }
  };

export const requestPasswordReset = ({ email }) => async () => {
  try {
    const { error } = await authApi.requestPasswordReset({ email });
    if (error) return { error };
    return { sucess: true };
  } catch (err) {
    return { error: 'unknown' };
  }
}

export const confirmAccount = ({ token }) => async () => {
  if (!token) return { error: 'tokenNotFound' };
  try {
    const requestResponse = await authApi.confirmAccount({ token });
    return requestResponse;
  } catch (err) {
    return { error: 'unknown' };
  }
}

export const activateAccount = ({ password, passwordConfirmation, token }) => async () => {
  try {
    if (password !== passwordConfirmation) return { error: 'passwordMismatch' };
    if (password?.length < MINIMUM_PASSWORD_LENGTH) return { error: 'passwordTooShort' };
    const { error } = await authApi.activateAccount({ password, passwordConfirmation, token });
    if (error) return { error };
    goToAppHomePage();
    return {};
  } catch (err) {
    return { error: 'unknown' };
  }
}

export const resetPassword = ({ password, passwordConfirmation, token }) => async () => {
  try {
    if (password !== passwordConfirmation) return { error: 'passwordMismatch' };
    if (password?.length < MINIMUM_PASSWORD_LENGTH) return { error: 'passwordTooShort' };
    const { error } = await authApi.resetPassword({ password, passwordConfirmation, token });
    if (error) return { error };
    goToAppHomePage();
    return {};
  } catch (err) {
    return { error: 'unknown' };
  }
}

export const requestRegister = data => async (_, getState) => {
  try {
    const { env } = getState();
    const mappedData = authMapper.register.toApi({
      ...data,
      locale: env.locale,
    });
    const response = await authApi.requestRegister(mappedData);
    if (response?.error) return { error: response?.error };
    return { sucess: true };
  } catch (err) {
    return { error: 'unknown' };
  }
}

export const requestRegisterHubspot = (data, utm_source) => async (_, getState) => {
  const { env } = getState();
  const intl = getIntl();

  let error = false;

  try {
    const handleDataPost = Object.entries(data).reduce((acc, [, value]) => {
      if (value.name === 'password' && value.value?.length < 12) {
        toast(intl.formatMessage(({ id: 'toast.error.passwordRegister' })), { type: 'error' })
        error = true;
      }
      return ({
        ...acc,
        [value.name]: value.value,
      })
    }, {});

    const params = Object.assign(
      handleDataPost,
      { userType: 'advertiser', locale: env.locale },
      utm_source ? {
          sources: {
            utms: {
              utm_source,
            },
          },
        } : null,
    );

    if (error) return false;

    const response = await authApi.requestRegister(authMapper.submitRegisterForm.toApi(params));

    if (response?.error) return console.error(response.error);
    return true;
  } catch (err) {
    return console.error(err);
  }
}
