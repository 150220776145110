import { useSelector } from 'utils/redux';

const WHITE_LIST_IDS = [20686, 21080, 12742, 18029, 26463, 28332];

// 20686 - Michella
// 21080 - Romain
// 12742 - Max
// 18029 - Ludivine
// 26463 - Fleur
// 28332 - Fati

const useWhiteListUser = () => {
  const userId = useSelector(({ user }) => Number(user.profile.id));

  return WHITE_LIST_IDS.includes(userId);
};

export const isWhitelisted = (id) => WHITE_LIST_IDS.includes(Number(id));

export default useWhiteListUser;