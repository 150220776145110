import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'config/store';
import toast from 'utils/toast';
import { getIntl } from 'utils/HOCs/IntlGlobalSingleton';

import * as api from './nsmDashboard.api';
import {
  DataFromApi,
  Mention,
} from './nsmDashboard.types';

export const saveSetup = createAsyncThunk<
  DataFromApi,
  {mentions: Mention[], isUpdate: boolean},
  { state: RootState }
>(
  'nsmDashboard/saveSetup',
  async ({ mentions, isUpdate }) => {
    const response = isUpdate
      ? await api.updateSetup(mentions)
      : await api.saveSetup(mentions)

    if (!response?.error) {
      const intl = getIntl();
      toast(
        intl.formatMessage( { id: 'nsm.editSetup.success' }),
        { type: 'success' },
      );
      return Promise.resolve(response);
    } else {
      return Promise.reject(response.error);
    }
  },
);

export const loadData = createAsyncThunk<
  DataFromApi,
  void,
  { state: RootState }
>(
  'nsmDashboard/loadData',
  async (_, { getState }) => {
    const filters = getState().nsmDashboard?.lighthouse?.filters;

    const params = {
      period: filters.period,
      social_network_mentions: filters.social_network_mentions,
      audience_location: filters.audience_location?.length
        ? filters.audience_location
        : null
    };
    const response = await api.loadData(params);
    if (!response?.error) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response.error);
    }
  },
);
