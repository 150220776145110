/* eslint-disable no-extra-boolean-cast */
import get from 'lodash.get';

export const getKols = {
  fromApi: ({ total, rows }) => ({
    total,
    rows: rows.map(data => ({
      id: get(data, 'id', null),
      name: get(data, 'name', ''),
      fullName: get(data, 'full_name', ''),
      pseudo: get(data, 'pseudo', ''),
      avatar: get(data, 'picture_url', ''),
      contactable: get(data, 'contactable', false),
      contactableEmail: get(data, 'contactable_email', ''),
    }))
  })
}

export const user = {
  fromApi: data => ({
    id: get(data, 'id', null),
    name: get(data, 'name', ''),
    fullName: get(data, 'full_name', ''),
    pseudo: get(data, 'pseudo', ''),
    avatar: get(data, 'picture_url', ''),
    type: get(data, 'type', ''),
    profileActive: get(data, 'is_active', false),
  })
}

const mapAttachment = {
  fromApi: (a) => ({
    id: get(a, 'id'),
    name: get(a, 'filename', ''),
    url: get(a, 'url', ''),
    size: get(a, 'size', 0),
    base64: get(a, 'base64', ''),
  }),
  toApi: (a) => ({
    id: a.id,
    name: a.name,
    size: a.size,
    base64: a.base64,
    _destroy: a._destroy,
  }),
};

export const mapMessage = message => ({
  id: get(message, 'id', null),
  conversation_id: get(message, 'conversation_id', null),
  createdAt: get(message, 'created_at', null),
  subject: get(message, 'subject', null),
  isThreadStart: get(message, 'is_a_thread_start', null),
  content: get(message, 'content', ''),
  messageType: get(message, 'message_type', null),
  subType: get(message, 'subtype', null),
  hasGmail: get(message, 'has_gmail_sync', false),
  writer: user.fromApi(get(message, 'writer', {})),
  attachments: get(message, 'attachments', []).map(data => ({
    size: data.size,
    filename: data.filename,
    url: data.url,
    id: data.id,
  })),
});

export const mapConversation = conversation => ({
  id: get(conversation, 'id', null),
  status: get(conversation, 'status', ''),
  lastMessageAt: get(conversation, 'last_message_at', ''),
  subject: get(conversation, 'subject', null),
  participants: get(conversation, 'participants', []).map(p => user.fromApi(p)),
  highlight: get(conversation, 'highlight', []),
  unreadMessages: get(conversation, 'unread_messages_count', 0),
  hasGmail: get(conversation, 'has_gmail_sync', false),
  draft: {
    id: get(conversation, 'draft_message.id', null),
    content: get(conversation, 'draft_message.content', ''),
    attachments: get(conversation, 'draft_message.attachments', []),
    updatedAt: get(conversation, 'draft_message.updated_at', []),
  },
  lastMessage: mapMessage(get(conversation, 'last_message', {})),
  profile: user.fromApi(get(conversation, 'profile', {})),
  hasBeenRead: get(conversation, 'has_been_read', false),
  unread: get(conversation, 'unread_messages_count', true)?.length > 0,
  campaignId: get(conversation, 'project_id', null),
  campaignName: get(conversation, 'project_name', ''),
})

export const getMessages = {
  fromApi: data => ({
    total: get(data, 'total', 1),
    conversations: get(data, 'rows', []).map(mapConversation),
    gmailAddress: get(data, 'gmail_address', false),
    sendingMethod: get(data, 'sending_method', 'kolsquare'),
  })
}

const mapSubjectThreads = (thread) => ({
  id: get(thread, 'id', null),
  createdAt: get(thread, 'created_at', null),
  subject: get(thread, 'subject', null),
});

export const getConversationMessages = {
  fromApi: data => ({
    total: data.total,
    rows: get(data, 'rows', []).map(item => ({
      id: get(item, 'id', null),
      content: get(item, 'content', ''),
      subject: get(item, 'subject', null),
      isThreadStart: get(item, 'is_a_thread_start', null),
      attachments: get(item, 'attachments', []),
      createdAt: get(item, 'created_at', ''),
      hasGmail: get(item, 'has_gmail_sync', false),
      writer: user.fromApi(get(item, 'writer', {})),
      messageType: get(item, 'message_type', null),
      subType: get(item, 'subtype', null),
    }))
  })
}

export const getConversation = {
  fromApi: data => {
    return {
      id: get(data, 'id', null),
      readOnly: !get(data, 'is_writeable', false),
      projectId: get(data, 'project_id', null),
      projectName: get(data, 'project_name', ''),
      subject: get(data, 'subject', null),
      subjectThreads: get(data, 'threads', []).map(mapSubjectThreads),
      propositionStatus: get(data, 'proposition_status_id', ''),
      lastMessageAt: get(data, 'last_message_at', ''),
      hasBeenRead: get(data, 'has_been_read', false),
      hasGmail: get(data, 'has_gmail_sync', false),
      from: get(data, 'from', ''),
      profileActive: get(data, 'is_profile_active', false),
      draft: {
        id: get(data, 'draft_message.id', null),
        content: get(data, 'draft_message.content', ''),
        subject: get(data, 'draft_message.subject', null),
        attachments: get(data, 'draft_message.attachments', []).map(a => mapAttachment.fromApi(a)),
        updatedAt: get(data, 'draft_message.updated_at', []),
      },
      lastMessage: {
        id: get(data, 'id', null),
        content: get(data, 'last_message.content', ''),
        attachments: get(data, 'last_message.attachments', []).map(a => mapAttachment.fromApi(a)),
      },
      profile: {
        id: get(data, 'profile.id', null),
        firstName: get(data, 'profile.first_name', null),
        name: get(data, 'profile.name', null),
        pseudo: get(data, 'profile.pseudo', null),
        avatar: get(data, 'profile.picture_url', null),
        contactable: get(data, 'profile.contactable', false),
        contactableEmail: get(data, 'profile.contactable_email', null),
      },
      participants: get(data, 'participants', []).map(participant => user.fromApi(participant)),
    }
  }
};

export const newMessage = (data, force) => Object.assign(
  {},
  force ? { force } : null,
  { profile_ids: data.recipient.map(({ id }) => id) },
  data?.campaignId ? { project_id: data?.campaignId } : null,
  {
    message: {
      subject: data?.message?.subject || '',
      content: data?.message?.content,
      attachments: data?.message?.attachments?.map(a => mapAttachment.toApi(a)),
      send_with: Boolean(data?.message?.gmailAddress) ? 'gmail' : null,
      send_promo_code: data?.message?.sendPromoCode || false,
    }
  },
);

export const handleMessage = (data, force) =>
  Object.assign({}, force ? { force: true } : null, {
    message: {
      subject: data?.subject || '',
      content: data?.content,
      attachments: data?.attachments?.map(a => mapAttachment.toApi(a)),
      send_with: Boolean(data?.gmailAddress) ? 'gmail' : null,
      send_promo_code: data?.sendPromoCode || false,
    },
  });

export const createConversation = {
  toApi: (data, force) => newMessage(data, force),
  fromApi: data => data?.map(mapConversation),
}

export const sendMessage = {
  toApi: (data, force) => handleMessage(data, force),
  fromApi: message => mapMessage(message)
}

export const wsConversation = {
  toApi: data => JSON.stringify(handleMessage(data)),
  fromApi: message => mapMessage(JSON.parse(message))
}

export const wsConversations = {
  fromApi: data => get(data, 'conversations', []).map(mapConversation)
}

export const conversationGmailData = {
  toApi: code => ({
    authorization_code: code
  }),
  fromApi: data => ({
    gmailAddress: data?.gmail_address || null,
  })
};

export const getCampaigns = {
  fromApi: data => data.map(item => ({
    id: get(item, 'id', null),
    name: get(item, 'name', ''),
    author: get(item, 'author_name', ''),
    profileIds: get(item, 'profile_ids', []),
  }))
};

export const searchKolParams = {
  toApi: ({ name, mySelection, page, perPage, campaignId }) => Object.assign(
    {},
    {
      in_favorites_and_projects: mySelection,
      page: !page ? 0 : page,
      per_page: perPage
    },
    name ? { name } : null,
    campaignId ? { project_id: campaignId } : null,
  )
};

export const draft = {
  toApi: async (draft) => {
    const { editorToMarkdown } = await import('utils/richTextEditor');
    return {
      draft_message: {
        conversation_id: draft.conversationId,
        profile_ids: draft.profilesIds,
        project_id: draft.campaignId,
        content: editorToMarkdown(draft.content) || '',
        subject: draft.subject || '',
        attachments: (draft?.attachments || []).map((a) => ({
          name: a?.name,
          type: a?.type,
          size: a?.size,
          base64: a?.base64,
          _destroy: a?._destroy,
        })),
      },
    };
  },
};

export const findDraft = {
  toApi: ({ profileIds, campaignId }) => Object.assign(
    {
      profile_ids: profileIds || [],
    },
    campaignId && { project_id: campaignId }
  ),
}

export const mapDraft = (draft) => draft
  ? ({
      id: get(draft, 'id', null),
      createdAt: get(draft, 'created_at', ''),
      updatedAt: get(draft, 'updated_at', ''),
      conversationId: get(draft, 'conversation_id', null),
      campaign: {
        id: get(draft, 'project_id', null),
        name: get(draft, 'project_name', ''),
      },
      profiles: get(draft, 'participants', []).map(profile => ({
        id: get(profile, 'id', null),
        name: get(profile, 'name', ''),
        fullName: get(profile, 'full_name', ''),
        pseudo: get(profile, 'pseudo', ''),
        avatar: get(profile, 'picture_url', ''),
        type: get(profile, 'type', ''),
        isActive: get(profile, 'is_active', true),
      })),
      attachments: get(draft, 'attachments', []).map(a => mapAttachment.fromApi(a)),
      content: get(draft, 'content', ''),
      subject: get(draft, 'subject', null),
    })
  : null;

export const getDrafts = {
  fromApi: response => ({
    total: get(response, 'total', 0),
    rows: get(response, 'rows', []).map(mapDraft)
  }),
  toApi: parameters => ({
    name: parameters?.name?.toLowerCase() || '',
    page: parameters?.page || 0,
    per_page: parameters?.perPage || 20,
  })
}

export const saveTemplate = {
  toApi: data => ({
    title: data?.title?.trim(),
    content: data.content,
  })
}

const mapTemplate = {
  toApi: t => Object.assign({},
    {
      title: t?.title?.trim(),
      subject: t?.subject,
      content: t?.content,
      position: t?.position,
    },
    t?.id && {id: t?.id}
  ),
  fromApi: t => ({
    title: get(t, 'title',''),
    subject: get(t, 'subject',''),
    content: get(t, 'content',''),
    id: get(t, 'id',''),
    position: get(t, 'position', 0),
  })
}

export const createTemplateToApi = template => {
  return {
    message_template: mapTemplate.toApi(template)
  }
}

export const updateTemplates = templates => {
  return {
    message_templates: templates.map(t => mapTemplate.toApi(t))
  }
}

export const getTemplates = templates => {
  return templates
    ?.map(t => mapTemplate.fromApi(t))
    ?.sort( (a, b) => b.position - a.position)
}
