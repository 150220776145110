import routes from '../config/routes';

export const isCampaignPage = () =>
  window.location.pathname?.toLowerCase()?.indexOf(`/${routes.campaigns}`) > -1;

export const isCampaignDiscountCodesPage = () =>
  isCampaignPage() &&
  window.location.pathname?.toLowerCase()?.indexOf(`/${routes.discountCode}`) >
    -1;
export const isCampaignMonitorPage = () =>
  isCampaignPage() &&
  window.location.pathname?.toLowerCase()?.indexOf(`/${routes.reporting}`) > -1;
export const isCampaignMessagingPage = () =>
  isCampaignPage() &&
  window.location.pathname?.toLowerCase()?.indexOf(`/${routes.messaging}`) > -1;
export const isCampaignTrackingLinksPage = () =>
  isCampaignPage() &&
  window.location.pathname?.toLowerCase()?.indexOf(`/${routes.trackingLinks}`) >
    -1;
export const isCampaignPaymentPage = () =>
  isCampaignPage() &&
  window.location.pathname?.toLowerCase()?.indexOf(`/${routes.campaignPayment}`) >
    -1;
export const isMyInfluencersPage = () =>
  window.location.pathname?.toLowerCase()?.indexOf(`/${routes.myKOLs}`) > -1;

export const isMyKolsManagePage = () =>
  isMyInfluencersPage() &&
  window.location.pathname.toLowerCase().indexOf(`/${routes.manage}`) > -1;

export const isMyKolsFollowPage = () =>
  isMyInfluencersPage() &&
  window.location.pathname.toLowerCase().indexOf(`/${routes.follow}`) > -1;

export const isDashboardPage = () =>
  window.location.pathname?.indexOf(`/${routes.dashboard}`) > -1;

export const isFollowersIncreasePage = () =>
  window.location.pathname?.indexOf(`/${routes.followersIncrease}`) > -1;

export const isListeningPage = () =>
  window.location.pathname?.toLowerCase()?.indexOf(`/${routes.listening}`) > -1;

export const isListeningDetailsPage = () => {
  const regex = new RegExp(`/${routes.listening}/[0-9]+`);
  return regex.test(window.location.pathname?.toLowerCase());
};

export const isMessagingPage = () =>
  window.location.pathname?.toLowerCase()?.indexOf(`/${routes.messaging}`) > -1 &&
  window.location.pathname?.toLowerCase()?.indexOf(`/${routes.campaigns}/`) === -1;

export const isPublicReportingPage = () =>
  window.location.pathname?.toLowerCase()?.includes(routes.publicReport);

export const isContentSearchPage = () =>
  window.location.pathname.toLowerCase().indexOf(`/${routes.contentSearch}`) > -1;
